import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle,faPaperclip, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';

import TableSearchDropDown from '../Components/TableSearchDropDownForSalesOrder';
import  '../Css/Tooltip.css';
import { useNavigate ,useLocation} from 'react-router-dom';
import * as XLSX from 'xlsx';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = React.useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <div
                  key={option.value}
                  className="p-2 cursor-pointer text-sm hover:bg-blue-500 hover:text-white"
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const NewPurchaseOrder = () => {
  const [selectedProject, setSelectedProject] = useState('');
const [selectedProjectId, setSelectedProjectId] = useState('');
const navigate = useNavigate()
const dropdownRef = useRef(null); // Define the dropdownRef variable
  const [quoteTypeOptions, setQuoteTypeOptions] = useState([
    { label: "General", value: "General" },
    { label: "Group", value: "Group" },
  ]);
  const [selectedQuoteType, setSelectedQuoteType] = useState(); // Initial value
  const [TaxOptions] = useState([
    {label: "Tax Exclusive", value: "Tax Exclusive"},
    {label: "Tax Inclusive", value: "Tax Inclusive"},
  ]);
  const [selectedTax, setSelectedTax] = useState({label: "Tax Exclusive", value: "Tax Exclusive"});
  const [priceLists , setPriceLists] = useState([ ]);
  //console.log(priceLists);
  const[selectedPricList, setselectedPricList] = useState('');
  const [UomOptions , setUomOptions] = useState([]);
  const [AreaMeasurementsData, setAreaMeasurementsData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedVendor, setselectedVendor] = useState();
 // console.log(selectedVendor);
  const [quotationNumber, setQuotationNumber] = useState('');
  const [purchaseorderDate, setpurchaseorderDate] = useState(new Date());
  const [showDesignSelectPopup, setshowDesignSelectPopup] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [Summary , setSummary] =  useState({});
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  console.log(selectedProductCategory)
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit
  const Taxes = [{name:'GST' , value: 'GST'},{name:'IGST' , value: 'IGST'}]
    
  const [total, setTotal] = useState(0);
  const [amendedDiscount, setAmendedDiscount] = useState();
  const [amendedPrice, setAmendedPrice] = useState();
 
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPricelists, setRowPricelists] = useState({});
  const [rows, setRows] = useState([{ item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',},  quantity: '', UOM: '', cost_price: '', tax: '', tax_type:'',amount: '', designs:[]}]);
  const [products, setProducts] = useState([]); // assume products is set to one of the arrays you provided
  const [amounts, setAmounts] = useState([]);
  const [items, setItems] = useState([{ tilesArea: '', doorsArea: '', windowsArea: '' }]);
  const [tilesOptions, setTilesOptions] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [adjustment, setAdjustment] = useState('');
  const[ attributes, setAttributes]= useState([])
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const location = useLocation();
    const selectedCategories = location?.state?.selectedItemWiseSalesOrder || location?.state?.selectedCategories;
    const [insurance, setInsurance] = useState(0);
    const [SelectedItemRow,setSelectedItemRow] = useState()
    const [SelectedItemRowIndex ,setSelectedItemRowIndex] = useState()
    const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES; 
const [searchTerm, setSearchTerm] = useState('');
const  [SubTotal,setSubTotal]= useState(0);
    console.log(selectedCategories);
    const [showDoorsAndWindowsBreakUpPopup, setShowDoorsAndWindowsBreakUpPopup] = useState(false);
    const [breakUp,setBreakUp] = useState([]);
    console.log(breakUp)
    const [BOMId, setBOMId] = useState('');
    const [roleId, setRoleId] = useState(null);


    useEffect(() => {
      fetchRoleId();
      fetchAllVendors();
      handleGetAllUoms();
    }, [fetchUrl]);

    const fetchRoleId = () => {
      const roleId = JSON.parse(sessionStorage.getItem('roleId'));
      setRoleId(roleId);
    };
  
    const categoriesToDisplay = roleId === 6 
    ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
    : productCategories;
      
    const fetchAllVendors = async (attempt = 1) => {
      console.log(selectedCategories)
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `vendor/get-all-vendors?organizationId=${sessionStorage.getItem('organizationId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
       // console.log(output)
        if (output) {
          // Format data for dropdown
          const formattedVendors = output.map(vendor => ({
            id: vendor._id,  // Use vendor ID as value
            label: vendor.name, // Use vendor name as label
            value: vendor.name
          }));
    
          setVendors(formattedVendors); // Update state with formatted data
          //console.log(formattedVendors);
          if(selectedCategories){
            console.log(selectedCategories[0].vendor_id)
          const selectedVendor = formattedVendors.find(vendor => vendor.id === selectedCategories[0].vendor_id);
          setselectedVendor(selectedVendor);
          //console.log(selectedVendor);
          fetchProductCategories(selectedVendor.id);
          }
         // console.log(formattedVendors);
        } else {
          if(response.status === 500){
            //console.log('retry enterd')
            
            setRetryFunction(() => () => fetchAllVendors); 
          }
          //console.error(data.message);
        }
      }  catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAllVendors(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAllVendors(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

    const fetchPrurchaseOrderItems = async (attempt = 1) => {
      try {
       // console.log(selectedCategories)

      /*   const result = selectedCategories.map(obj => ({ salesorder_id: obj.salesOrderId, item_id: obj.itemId })); */
//console.log(result)
        const token = sessionStorage.getItem('token');
       // const requestBody = { /* add your request body data here */ };
        const response = await fetch(fetchUrl + `customer/purchaseorder/items-to-create-purchaseorder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          },
          body: JSON.stringify(selectedCategories) // convert request body to JSON
        });
        const data = await response.json();
        const output = await handleSaveResponse(response , data)  
        console.log(output)  
        if (output) {
        const allRows = await  transformOutputData(output)
       // console.log(allRows)
          setRows(allRows)
          setShowPopup(false);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
    
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchPrurchaseOrderItems(attempt + 1), 1000); // Retry after 1 second
        } else {
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchPrurchaseOrderItems(attempt)); // Set retry function
        }
      } /* finally {
        setIsLoading(false);
      } */
    };

    const fetchSalesOrderAndOrderItems = async (attempt = 1) => {
      try {
        console.log(selectedCategories)
        const salesOrderId = selectedCategories[0].salesOrderId;
        const token = sessionStorage.getItem('token');
       // const requestBody = { /* add your request body data here */ };
        const response = await fetch(fetchUrl + `customer/salesorder/get-salesorder-to-create-po?salesorder_id=${salesOrderId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          },
        });
        const data = await response.json();
        const output = await handleSaveResponse(response , data)  
        console.log(output)  
        if (output) {
        //const allRows = await  transformOutputData(output)
       console.log(output)
          setBOMId(output.BOM_id)
          setRows(output.items)
          console.log(output.items)
          setBreakUp(output?.BOMData)
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
    
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchSalesOrderAndOrderItems(attempt + 1), 1000); // Retry after 1 second
        } else {
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchSalesOrderAndOrderItems(attempt)); // Set retry function
        }
      } /* finally {
        setIsLoading(false);
      } */
    };

    const transformOutputData = (output) => {
      return output.map(item => {
        const taxRate = item.IGST || 0;
        const quantity = parseFloat(item.quantity) || 0;
        const costPrice = parseFloat(item.cost_price) || 0;
        const taxAmount = (taxRate / 100) * costPrice * quantity;
    
        return {
          ...item,
          tax_type: "IGST",
          tax: taxRate,
          total_tax_amount: taxAmount,
          total: costPrice * quantity + taxAmount
        };
      });
    };
    


  const fetchProductCategories = async (vendorId, attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + `product-category/get-productCategoriesBy-vendor_id?vendor_id=${vendorId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data)
      if (output) {
        setProductCategories(output); // Update productCategories state
        const formattedCategories = output.map(category => ({
          id: category._id,
          label: category.product_category_name,
          value: category._id
        }));
        
        if (selectedCategories && selectedCategories.length > 0) {
          const selectedProductCategory = formattedCategories.find(category => category.id === selectedCategories[0]?.productCategoryId);
          setSelectedProductCategory(selectedProductCategory)
          console.log(selectedCategories[0]?.salesOrderId)
          if(selectedCategories[0]?.salesOrderId){
            console.log("calling so with id")
            fetchSalesOrderAndOrderItems();
          } else {
            fetchPrurchaseOrderItems();
          }
        } else {
          console.log("No categories selected");
          // Handle the case when no categories are selected
        }
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(vendorId, attempt + 1), 1000); // Retry after 1 second
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(vendorId, attempt)); // Set retry function
      }
    }
  };
  


    const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
        //console.log(output)
         setAttributes(output)
        } else {
          setAttributes([])
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
        }
      }
      }

    const fetchProductsbyProductCategoryID = async (product_category, attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product/get-products-by-product-category?product_category_id=${product_category}&organizationId=${sessionStorage.getItem('organizationId')}&vendorId=${sessionStorage.getItem('vendorId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
         // console.log(output)
          const productsWithItemName = output.map((product) => {
            // Match the item_id with the rows and get the corresponding item_name
            const matchedRow = rows.find(row => row.item_id === product.item_id);
            const itemName = matchedRow ? product.item_name : ''; // Map the item_name

            return {
                ...product,
                itemName, // Add itemName directly to the product object
            };
        });
        //console.log(productsWithItemName)
        setProducts(output);
         // setProducts(output); // Update productCategories state
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchProductsbyProductCategoryID(attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchProductsbyProductCategoryID(attempt)); // Set retry function
        }
      }/* finally {
        setIsLoading(false);
      } */
    };

    const handleGetAllUoms = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
    
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        if (output) {
          // Log the data to ensure it's fetched correctly
          //console.log('Fetched uoms:',output);
    
          const uomData = output.map((item) => ({
            id: item._id,
            value: item.UOM,
            label: item.UOM,
          }));
    
      setUomOptions(uomData)
        } else {
          console.error('Error:', data.message);
          return ([])
        
         
        }
      }catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

    const fetchAllPriceLists = async (ProductCategory,attempt = 1) => {
      if(selectedProductCategory?.value){
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + `pricelist/get-pricelists-with-productrates-by-product-category?product_category_id=${ProductCategory.value}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
         
          const output = await handleGetResponse(response , data)
          if (output) {
           // 
            console.log(output);
            setPriceLists(output); // Update productCategories state
          } else {
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchAllPriceLists(ProductCategory, attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchAllPriceLists(ProductCategory, attempt)); // Set retry function
          }
        }/* finally {
          setIsLoading(false);
        } */
      }
      
    };


  const handleDateChange = (date) => {
    setpurchaseorderDate(date);
  };

  const handleQuotationNumberChange = (e) => {
    setQuotationNumber(e.target.value);
  };

  const handleProjectSelect = (value, label, id) => {
    setSelectedProject(label);
  };

  const handlePriceListSelect = (option) => {
    setselectedPricList(option);
  }

  const handleTaxSelect = (option) => {
    setSelectedTax(option);
  };

  const handleQuoteTypeChange = (newQuoteType) => {
    //console.log(newQuoteType)
    setSelectedQuoteType(newQuoteType);
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', cost_price: '', tax: '', total: '', designs: [] };
    setRows([ newRow]);
  };
  
  const handleInputChange = (value, index, key, cost_price) => {
    const updatedData = [...rows];
    
    // Update the specific key in the row data
    updatedData[index][key] = value;
  
    // If the key is 'quantity', calculate the total and update the 'amount' in the same row
    if (key === 'quantity') {
      const total = parseFloat(value) * parseFloat(cost_price);
/*       updatedData[index].amount = isNaN(total) ? "" : total; // Handle potential NaN values
  
      // Update the amounts array if needed (optional)
      const newAmounts = [...amounts];
      newAmounts[index] = updatedData[index].amount;
      setAmounts(newAmounts); */
      const totaltaxOfproduct = calculateTaxAmount(updatedData[index]?.tax || 0 , cost_price, value);
      updatedData[index].total_tax_amount = totaltaxOfproduct;
      updatedData[index].total = total;
    }
  
    // Update the rows state
    setRows(updatedData);
  };
  
  
  const handleSelectDesignPopup = (index) => {
    const selectedRow = rows[index];
    console.log(selectedRow)
    setSelectedItemRow(selectedRow)
    setSelectedItemRowIndex(index)
    //setSubItems(selectedRow?.sub_items); 
    setshowDesignSelectPopup(true);
    /* if(!products.length > 0) {
      rows[index].selectedDesigns = selectedRow?.sub_items
    }else{
      rows[index].selectedDesigns = []
    } */
  };


  const handleClosePopup = () => {
    setshowDesignSelectPopup(false);
  }

 /*  const handleSelectDesign = (design) => {
    setSelectedDesigns(prevState => {
      const isSelected = prevState.some(item => item.id === design.id);
      if (isSelected) {
        return prevState.filter(item => item.id !== design.id);
      } else {
        return [...prevState, { ...design, quantity: 1 }];
      }
    });
  }; */

  const handleQuantityChange = (id, quantity) => {
    setSelectedDesigns(prevState => 
      prevState.map(item => item.id === id ? { ...item, quantity } : item)
    );
  };

  const isSelected = (id) => selectedDesigns.some(item => item.id === id);

  const handleVendorSelect = (option) => {
    //console.log(option)
    setSelectedProject(null); // Reset the selected project
    setSelectedProjectId(null); // Reset the selected project ID
    setSelectedProductCategory(null); // Reset the selected product category
    setProductCategories([]); 
    setselectedVendor(option); // Set the selected option object
    fetchProductCategories(option.id);
  };
  
 

  const handleRowPriceListSelect = (index, option) => {
    setRowPricelists((prev) => ({
      ...prev,
      [index]: option
    }));
  };
  
  const handleProductCategorySelect = (selectedOption) => {
    fetchAllPriceLists(selectedOption);
    setSelectedProductCategory(selectedOption);
    setSelectedProject(null); // Reset the selected project
    setSelectedProjectId(null); // Reset the selected project ID
    fetchProductsbyProductCategoryID(selectedOption.value);
    fetchAttributesByproductCategory(selectedOption.value)
    const newRow = { item_name: '', item_id:'',  quantity: '', UOM: '', cost_price: '', tax: '', total: '', designs: [] };
    setRows([newRow]);
    const index = 0
    const option = {label: '' , value: ''}
    handleItemNameChange(index ,option)
  };



  const addRow = () => {
    const newRow = { item_name: '', item_id:'', quantity: '', UOM: '', cost_price: '', tax: '', total: '', designs: [] };
    setRows([...rows, newRow]);
  };

  const deleteRow = (index) => {
    const updatedData = [...rows];
    updatedData.splice(index, 1);
    setRows(updatedData);
  };
  
 /*  const handleUomChange = (index, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    const uomid= selectedOption?selectedOption.id:null
      const updatedData = [...rows];
      updatedData[index].UOM = value;
      updatedData[index].UOM_id = uomid;
      //setData(updatedData);
      setRows(updatedData)
  
  }; */

  const handleItemNameChange = (index, selectedOption) => {
    //console.log(selectedOption)
    const updatedRows = [...rows];
  const selectedItem = products.find(product => product.item_id === selectedOption.value)|| null;

   // console.log(products)
   // console.log(selectedItem)
   
              // Set default tax to GST
  const defaultTax = Taxes.find(tax => tax.value === 'IGST');
  if (defaultTax) {
    const taxValue = selectedItem?.IGST || 0;
    updatedRows[index].tax = taxValue;
    updatedRows[index].tax_type = 'IGST';
    handleTaxChange(index, defaultTax, taxValue, updatedRows[index].price, updatedRows[index].quantity);
  } 

    if (selectedItem) {
      updatedRows[index].item_id = selectedItem?.item_id
      updatedRows[index].item_name = selectedItem?.item_name
      updatedRows[index].cost_price = selectedItem?.cost_price
      updatedRows[index].item_image = selectedItem?.item_image
      updatedRows[index].selectedDesigns = [];
      const product = products.find(p => p.item_id === selectedItem.item_id)
      const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id )
      //console.log(selectedUom)
      updatedRows[index].UOM = selectedUom?.value;
      updatedRows[index].UOM_id = selectedUom?.id;
      if(products.length > 0){
        updatedRows[index].sub_items = selectedItem.sub_items;
      }
      // If you want to set these values as well, uncomment the following lines
      // updatedRows[index].UOM_id = selectedItem.UOM_id
      // updatedRows[index].GST = selectedItem.GST
      // updatedRows[index].IGST = selectedItem.IGST
      // updatedRows[index].sub_items = selectedItem.sub_items
      handleInputChange(selectedItem.cost_price, index , 'price')
    
      setRows(updatedRows);
    }
  
  }

/*   const handleAreaChange = (index, selectedOption) => {
    const value = selectedOption ? selectedOption.area : '';
    const areaName = selectedOption ? selectedOption.areaName : '';
    const count = selectedOption ? selectedOption.count : '';
   
    const updatedData = [...rows];
    updatedData[index].application_area.area_name = areaName;
    updatedData[index].application_area.area = value;
    updatedData[index].application_area.count = count;
  
    setRows(updatedData);
    const product = products.find(p => p.item_id === updatedData[index].item_id);
    //console.log(selectedQuoteType);
  
    if (selectedQuoteType.value === 'Group') {
      const product_category = productCategories.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);
       
      if (category_mapping_id === 1) {
        
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
        let conversionFactor = 0;
  
        product.attribute_value_ids.forEach((id) => {
          const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
          conversionFactor = attributevalue;
        });
  
        const quantity = parseFloat(value )/parseFloat( conversionFactor);
        console.log(quantity)
        handleInputChange(quantity,index, 'quantity', product?.selling_price );
      }else{
        handleInputChange(value ,index, 'quantity', product?.selling_price );
      }
    }
  }; */

  const handleAreaChange = (index, selectedOption) => {
    const value = selectedOption ? selectedOption.area : '';
    const areaName = selectedOption ? selectedOption.areaName : '';
    const count = selectedOption ? selectedOption.count : '';
  
    const updatedData = [...rows];
    updatedData[index].application_area.area_name = areaName;
    updatedData[index].application_area.area = value;
    updatedData[index].application_area.count = count;
  
    setRows(updatedData);
    const product = products.find(p => p.item_id === updatedData[index].item_id);
  
    if (selectedQuoteType.value === 'Group') {
      const product_category = categoriesToDisplay.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);
  
      if (category_mapping_id === 1) {
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
        //console.log(attributeValues)
        let conversionFactor = 0;
        if (product && product.attribute_value_ids) {
          for (const id of product.attribute_value_ids) {
            const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
            if (attributevalue) {
              conversionFactor = parseFloat(attributevalue.attribute_value);
              break; // Stop iterating once we find a match
            }
          }
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
            //console.log(quantity)
            handleInputChange(quantity, index, 'quantity', product?.cost_price);
          } else {
            handleInputChange(value, index, 'quantity', product?.cost_price);
          }
        } else {
          handleInputChange(value, index, 'quantity', product?.cost_price);
        }
      } else {
        handleInputChange(value, index, 'quantity', product?.cost_price);
      }
    }
  };

  const handleAreaValueChange = (value, index, key, item_id) => {
    const updatedRows = [...rows];
    const [parentKey, childKey] = key.split('.');
    updatedRows[index][parentKey][childKey] = value;
    setRows(updatedRows);
    const product_category = categoriesToDisplay.find(p => p._id === selectedProductCategory.value);
    const category_mapping_id = parseFloat(product_category?.category_mapping_id);

    const product = products.find(p => p.item_id === item_id);
    if (category_mapping_id === 1) {
      const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
      //console.log(attributeValues)
      let conversionFactor = 0;
      if (product && product.attribute_value_ids) {
        for (const id of product.attribute_value_ids) {
          const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
          if (attributevalue) {
            conversionFactor = parseFloat(attributevalue.attribute_value);
            break; // Stop iterating once we find a match
          }
        }

        if (conversionFactor > 0) {
          const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
          //console.log(quantity)
          handleInputChange(quantity, index, 'quantity', product?.cost_price);
        } else {
          handleInputChange(value, index, 'quantity', product?.cost_price);
        }
      } else {
        handleInputChange(value, index, 'quantity', product?.cost_price);
      }
    } else {
      handleInputChange(value, index, 'quantity', product?.cost_price);
    }
  
   
    
  }
  

  const handleAmountInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    
    // Assuming you have a state like `updatedRows` that holds your table data
    const updatedRows = [...rows]; 
  
    updatedRows[index] = {
      ...updatedRows[index],
      [fieldName]: value
    };
  
    // Set the updated rows back to state
    setRows(updatedRows);
  };




  useEffect(()=>{
    calculateSubtotal()
    calculateTax()
  },[rows])
  
  
  const calculateSubtotal = () => {
    let subtotal = 0;
  
    rows.forEach(row => {
      const quantity = parseFloat(row?.quantity) || 0;
      const price = parseFloat(row?.cost_price) || 0;
      const rowTotal = quantity * price;
      subtotal += rowTotal;
    });
   setSubTotal(subtotal)
    return subtotal;
  };
  
 /*  const calculateDiscount = () => {
    const subtotal = calculateSubtotal();
    if (subtotal === '0.00') {
      return '0.00'; // or some other default value
    }
    const discountAmount = (subtotal * discountPercentage) / 100;
    return discountAmount.toFixed(2);
  };
  
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    if (subtotal === '0.00') {
      return '0.00'; // or some other default value
    }
    const discountAmount = calculateDiscount();
    const adjustmentValue = adjustment ? (adjustment.startsWith('-') ? -parseFloat(adjustment.slice(1)) : parseFloat(adjustment)) : 0;
    const total = subtotal - discountAmount + adjustmentValue;
    return total.toFixed(2);
  };
 */
 
  const calculateTax = () => {
    let TotalTax = 0;
  
    rows.forEach((p) => {
      const price = parseFloat(p.cost_price) || 0;  // Handle missing or invalid price
      const taxRate = parseFloat(p.tax) || 0;  // Handle missing or invalid tax rate
      const quantity = parseFloat(p.quantity) || 0;  // Handle missing or invalid quantity
  
      const tax = (taxRate / 100) * price;
      const SubTotalTax = tax * quantity;
  
      TotalTax += SubTotalTax;
    });
  
    return TotalTax;
  };
  

  const calculateTotal = () => {
    const subtotal = calculateSubtotal() || 0; // Get the subtotal from the existing function
    const discount = (amendedDiscount / 100) * subtotal || 0; // Calculate the discount based on the amendedDiscount percentage
    const tax_amount = calculateTax() || 0; // Calculate the tax amount using the existing function
    console.log(tax_amount);
  
    const parsedAmendedPrice = parseFloat(amendedPrice) || 0; // Parse amendedPrice to ensure it's a valid number
    const insuranceAmount = parseFloat(insurance) || 0; // Parse insurance to ensure it's a valid number
    
    // Adjust the subtotal based on amended price
    const adjustedSubtotal = subtotal - discount + parsedAmendedPrice;
    
    let total = adjustedSubtotal + insuranceAmount; // Add insurance to the adjusted subtotal
    
    if (selectedTax) {
      if (selectedTax.value === 'Tax Exclusive') {
        total += tax_amount; // Add tax if it's exclusive
      }
      // For 'Tax Inclusive', no need to adjust since total is already calculated
    } else {
      // If selectedTax is not defined, assume tax is exclusive
      total += tax_amount;
    }
    
    return total;
  };
  
  useEffect(() => {
    const calculatedTotal = calculateTotal();
    setTotal(calculatedTotal);
  }, [rows, amendedDiscount, amendedPrice, selectedTax, insurance]);


  const handleTaxChange = (index, selectedOption, value, price, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      tax: value,
      tax_type: selectedOption?.label,
    };
    const totaltaxOfproduct = calculateTaxAmount(value, price, quantity);
    updatedRows[index].total_tax_amount = totaltaxOfproduct;
    setRows(updatedRows);
  };
  
  const calculateTaxAmount = (taxRate, cost_price, quantity) => {
    const tax = parseFloat(taxRate) || 0;
    const sellingPricePerQuantity = parseFloat(cost_price) || 0;
    const totalQuantity = parseFloat(quantity) || 0; // Ensure quantity is a number
    const taxAmount = parseFloat(((tax / 100) * sellingPricePerQuantity) * totalQuantity) || 0;
    return taxAmount;
  };

  const calculateTotalTaxAmount = () => {
    let totalTaxAmount = 0;
   
    if (Array.isArray(rows)) {
      rows.forEach((row) => {
        totalTaxAmount += row?.total_tax_amount || 0;
      });
    }
  
    return totalTaxAmount; // Update the total tax amount state
  };
  //console.log(rows)


  const calculateEachProductTotal = (discount, CostPrice, quantity ) => {
    // Ensure sellingPrice and quantity are numbers
    const price = parseFloat(CostPrice) || 0;
    const qty = parseFloat(quantity) || 0;
    
    // Calculate the initial total without discount
    let total = price * qty;
  
    // If the discount is provided and is a valid number, apply it
    if (discount && !isNaN(discount) && parseFloat(discount) > 0) {
      total = total * (1 - parseFloat(discount) / 100);
    }
    return total;
  };

const handleTheItemsData = () => {
  console.log(rows)
  const data = rows.map((p) => {
    console.log(p)
    const itemData = {
      item_name: p?.item_name,
      item_id: p?.item_id,
      quantity: p?.quantity || 0,
      count: p?.count || 0,
      cost_price:  p?.cost_price||0,
      selling_price: p?.price || 0,
      discount: parseFloat(p?.discount) || 0,
      tax_rate: parseFloat(p?.tax) || 0,
      tax_type: p?.tax_type,
      total_tax_amount: p?.total_tax_amount || 0,
      tax_amount: (parseFloat(p?.cost_price) * parseFloat(p?.tax / 100)) * parseFloat(p?.quantity) || 0,
      total: calculateEachProductTotal(p?.discount, p?.cost_price, p?.quantity) || 0,
      quantity_breakup: p?.quantity_breakup || {},
      UOM: p?.UOM || '',
      UOM_id: p?.UOM_id,
      required_order_breakup: p?.required_order_breakup || [],
      salesorder_ids:p?.salesorder_ids|| [p?.salesorder_id] || [],
      orderitem_ids:p?.orderitem_ids|| [p?._id] || [],
    };
   
    if (p.price_list_id) {
      itemData.price_list_id = p.price_list_id;
    }
    if (p.sub_items && 
      p.sub_items.length > 0) {
     console.log(p)
      const subItems = p.selectedDesigns
        .map((item) => {
         
              return {
                sub_item_name: item.sub_item_name,
                sub_item_id: item.sub_item_id,
                sub_item_quantity: item?.sub_item_quantity,
              };
            
         
        })
    
      itemData.required_order_breakup = subItems;
    } 
    

    if (selectedQuoteType?.value === 'Group') {
      itemData.area_types = p?.application_area ;
      //itemData.selected_designs = [...p?.selected_designs];
    }
    //console.log(itemData)
    return itemData;
  });
 // console.log(data)
  return data;
};




const handleSavePurchaseOrderData = async (attempt = 1) => {
  setIsSaving(true);
  try {
    //console.log(selectedVendor,selectedProductCategory)
    const purchaseorderData = {

      vendor_id: selectedVendor?.id || '',
      product_category_id: selectedProductCategory?.value || '',
      status: 'Draft',
      subtotal: await calculateSubtotal() || 0,
      tax_amount: await calculateTax() || 0,
      discount: discountPercentage || 0,
      adjustment: adjustment || 0,
      terms_and_conditions: '', // Add actual terms and conditions if needed
      total: await calculateTotal() || 0,
      purchaseorder_date:purchaseorderDate || '',
      insurance: insurance,
      BOM_ids:BOMId,
      organizationId:sessionStorage.getItem('organizationId'),
    };

    const ItemsData = await handleTheItemsData();

    const purchaseOrderData = {
      ...purchaseorderData,
      items: ItemsData
    };
 console.log(purchaseOrderData)
    const token = sessionStorage.getItem('token');

    const response = await fetch(fetchUrl+ 'customer/purchaseorder/create-purchaseorder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(purchaseOrderData)
    }); 
    const data = await response.json()
    //console.log(data)
     const output = await handleSaveResponse(response,data)
    if (output) {
      setTimeout(() => {
        setShowPopup(false);
      navigate('/bb/app/purchases/purchaseorderlist')
    }, 2000);
    }else{
      console.error(data.message || 'Unknown error');
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    // Retry logic in case of failure
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleSavePurchaseOrderData(attempt + 1), 1000); // Retry after 1 second
    } else {
      // If max retries reached, show an error popup
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleSavePurchaseOrderData(attempt)); // Set retry function
    }
  } finally {
    setIsSaving(false); // Ensure saving state is reset after attempt
  }
};

/* const handleAreaValueChange = (value, index, key) => {
  console.log(value, index, key);
  if (key === 'application_area.area') {
    rows[index].application_area.area = value;
  }
} */
  

  const handleInsuranceChange = (e) => {
    const value = e.target.value;

    // Check if the entered value is a number
    if (!isNaN(value) || value === '') {
      setInsurance(value); // Update insurance state only if the input is a valid number
    }
  };

  const handleClose = () => {
    navigate(-1);
  }


  const handleSelectDesign = (design) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    const designIndex = selectedDesigns.findIndex((d) => d.sub_item_id === design.sub_item_id);
  
    if (designIndex !== -1) {
      // Design is already in the array, remove it
      selectedDesigns.splice(designIndex, 1);
    } else {
      // Design is not in the array, add it
      selectedDesigns.push({ ...design, quantity: 1 }); // Default quantity set to 1 when adding
    }
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = [...selectedDesigns];
    setRows(updatedRows); // Trigger a state update
  };
  
  
  const handleQuantityChangeForDesign = (subItemId, index, quantity) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = selectedDesigns.map((item) =>
      item.sub_item_id === subItemId ? { ...item, sub_item_quantity: quantity } : item
    );
  
    setRows(updatedRows); // Trigger a state update
  };


  if (isLoading) {
    return <Loader />;
  }

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
  // Format the number with commas and two decimal places
  return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };


  const handleSelectDoorsAndWindowsBreakUpPopup = (index,) => {
    setShowDoorsAndWindowsBreakUpPopup(true);

      //fetchBreakUpOfDoorsOrWindows()
console.log(rows);
  };

  const handleCloseDoorsAndWindowsBreakUpPopup = () => {
    setShowDoorsAndWindowsBreakUpPopup(false);
  }


/*   const fetchBreakUpOfDoorsOrWindows = async () => {
   const  projectId = '66cd5ab6f834a610465cc09c' ;
     try {
       const token = sessionStorage.getItem('token');
       const response = await fetch(`${FetchProjectsUrl}/measurements/get-computed-measurements-of-project-as-array?projectId=${projectId}&productCategoryId=${selectedProductCategory.value}`, {
     
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           'x-token': token
         }
       });
       console.log(response)
       const data = await response.json();
       
       if (response.ok) {
          console.log(data);
         setBreakUp(data);
 
       } else {
         console.error('Failed to fetch projects:', response.status, response.statusText);
       }
     } catch (error) {
       console.error('Failed to fetch projects:', error);
     } 
   }; */


   const handleExportAsExcel = () => {
    if (Array.isArray(breakUp) && breakUp.length > 0) {
      const exportData = breakUp.map(row => {
        if (row && typeof row === 'object') {
          return [
            row?.UIC, // UIC
            row.computedMeasurements?.length, // Height
            row.computedMeasurements?.breadth, // Width
            row.computedMeasurements?.areaInSFT, // Area
            row?.ItemName, // Item Code
            row?.Installation
          ];
        } else {
          console.error('Invalid row data:', row);
          return [];
        }
      });
  
      const ws = XLSX.utils.aoa_to_sheet([
        ["UIC", "Height", "Width", "Area", "Item Code"], // Header row
        ...exportData // Data rows
      ]);
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "BreakUp");
  
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(data);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = 'break_up_data.xlsx';
      a.click();
  
      window.URL.revokeObjectURL(url);
    } else {
      alert('No data available to export');
    }
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">New Purchase Order</h1>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Vendor Name<span className="text-red-500">*</span>
                        </label>
                        <div className='w-1/3'>
                        <CustomDropdown
        options={vendors}
        selected={selectedVendor}
        onSelect={handleVendorSelect}
        placeholder='Select Vendor'
        showAddNew={false}
        showSearch={true}
      />
      </div>
                      </div>
                     
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                     <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="purchaseorderDate" className="block text-sm font-regular text-gray-700 w-1/6">
                        Date
                      </label>
                      <DatePicker
                        selected={purchaseorderDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                      />
                    </div>
                  
                    <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category<span className="text-red-500">*</span>
                </label>           
                    <div className='w-1/3'>
                    <CustomDropdown
  options={categoriesToDisplay.map(category => ({
    value: category._id,
    label: category.product_category_name
  }))}
  selected={selectedProductCategory}
  onSelect={handleProductCategorySelect}
  placeholder="Select Item Category"
  showAddNew={false}
  showSearch={true}
/>
                    </div>
                    
              </div>
             
                  </div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              </div>
              <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
              

              <div className="w-full container mx-auto px-4 mb-2 mt-4">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
  <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
        <thead>
          <tr className="font-semibold">
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">Item Details</th> 
            {(selectedProductCategory?.label === 'Doors' || selectedProductCategory?.label === 'Windows') && (
  <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">
    {selectedProductCategory?.label} Count
  </th>
)}            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Quantity</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">UOM</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Price</th>
            {/* <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Design</th> */}
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Tax</th>
            <th className="font-semibold border-b  border-r text-left text-sm py-2 px-2">Amount</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr key={index}>
              <td className="border-r border-b px-2 py-2" ref={dropdownRef}>
              <TableSearchDropDown
            options={products.map(product => ({
              value: product.item_id,
              label: product.item_name,
            }))} // Map item_name options
            value={item?.item_name} // Use item_id as value
            onChange={(selectedOption) => handleItemNameChange(index, selectedOption )} // Handle item_name change
          />
              </td>
               {/* Render additional fields only for Group type and specific categories */}
               {(selectedProductCategory?.label === 'Doors' || selectedProductCategory?.label === 'Windows') && ( <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.count}
                  onChange={(e) => handleInputChange(e.target.value, index, 'count' , products.find(p=>p.item_id === item?.item_id)?.count )}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td> 
              )}    
       <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.quantity}
                  onChange={(e) => handleInputChange(e.target.value, index, 'quantity' , products.find(p=>p.item_id === item?.item_id)?.cost_price )}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border-r border-b px-2 py-2">
                {/* <input
                  type="text"
                  value={item.UOM}
                  onChange={(e) => handleInputChange(e, index, 'UOM')}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                /> */}
                <td className="w-20">
  {/* <TableSearchDropDown
    options={UomOptions}
    value={item.UOM}
    onChange={(selectedOption) => handleUomChange(index, selectedOption)}
  /> */}
   <input
                  type="text"
                  value={item?.UOM}
                  readOnly
                  //onChange={(e) => handleInputChange(e, index, 'UOM')}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
</td>
              </td>
              <td className="border-r border-b px-2 py-2">
                {console.log(item.cost_price)}
                <input
                  type="text"
                  value={item?.cost_price}
                  readOnly
                  //onChange={(e) => handleInputChange(products.find(p=>p.item_id===item.item_id)?.selling_price, index, 'price')}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
                </td>

               {/*  <td className="border-b border-r px-2 py-2">
                <div
                  className="px-2 py-1 border rounded-md cursor-pointer text-center"
                  onClick={()=>handleSelectDesignPopup(index)}
                >
                  View
                </div>
              </td> */}
             
              <td className="border-r border-b px-2 py-2">
              {/*   <input
                  type="text"
                  value={item?.tax}
                  onChange={(e) => handleInputChange(e, index, 'tax')}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                /> */}
                <div className="w-48">
  <TableSearchDropDown
    options={Taxes.map(tax => ({
      value: tax.name,  // Set tax name as the value
      label: tax.value, // Set tax value as the label
    }))} 
    value={ item?.tax_type}
    onChange={(selectedOption) => {
      
      handleTaxChange(
        index,
        selectedOption,
        products.find(p => p.item_id === item.item_id)?.[selectedOption.value],
        item?.cost_price,
        item?.quantity
      );
    }}
  />
</div>

              </td>
             
             
              <td className="border-b  px-2 py-2">
  <input
    type="text"
    value={
     formatNumber(item?.cost_price * item?.quantity)
    }
    onChange={(e) => handleAmountInputChange(e, index, 'amount')}
    className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
  />
</td>             
<td className="px-2">  <button    type="button"
                                  onClick={() => deleteRow(index ,rows)}
                                  className="text-red-500">
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
            </tr>
          ))}
        </tbody>
      </table>
</div>
              </div>
              </div>
              </div>
              <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
              <div class="flex flex-col">
                <div><button  onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'><FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1"  />
                Add New Row</button></div> 
                {(selectedProductCategory && (selectedProductCategory.label === 'Doors' || selectedProductCategory.label === 'Windows')) && (
                <div>
                <button
                  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md mt-10"
                  onClick={handleSelectDoorsAndWindowsBreakUpPopup}
                >
                  <FontAwesomeIcon icon={faPaperclip} /> attachment
                </button>
                </div>
                )}
               </div>
                <div className='p-6 space-y-6 flex flex-col justify-between rounded-md w-1/2 bg-[#F7F7FE]'>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm font-semibold'>Sub Total</p>
                </div>
                <div><p className='text-sm font-semibold'>{formatNumber(SubTotal)}</p></div>
                </div>
                <div className='flex justify-between'>
                <div className='flex flex-row items-center'>
  <p className='text-sm'>Discount</p>
  <div className='flex justify-end w-full relative'>
  <input 
    type="text"
    value={discountPercentage}
    onChange={(e) => {
      const currentValue = e.target.value;
      const newValue = currentValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
      setDiscountPercentage(newValue);
      setAmendedDiscount(newValue);
    }}
    className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-1/3 border-r-0 rounded-l-md'
  />
  <span className='bg-gray-200 p-2 text-sm border border-gray-300 border-l-0 rounded-r-md'>%</span>
</div>
</div>
<div>
  <p className='text-sm '>
    <span className='mr-1 font-bold text-xl text-red-500'>-</span>
    {formatNumber(Number.isFinite(parseFloat(SubTotal)) && Number.isFinite(parseFloat(amendedDiscount)) ? parseFloat(SubTotal) * (parseFloat(amendedDiscount / 100)) : 0)}
  </p>
</div>

                </div>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm '>Tax</p>
                </div>
                <div><p className='text-sm font-semibold'>{formatNumber(calculateTotalTaxAmount())}</p></div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-sm '>Adjustment</p>
                <div className='flex justify-end w-full relative'>
                <input
  type="text"
  value={amendedPrice}
  onChange={(e) => {
    const regex = /^[-+]?[0-9]*$/; // allow optional - or +, followed by digits
    if (regex.test(e.target.value)) {
      setAmendedPrice(e.target.value);
    }
  }}
  className='px-2 w-[50%] text-right py-1 w-2/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
/>
  <button 
  className="mr-2 text-white px-1 py-0.5 rounded-md" 
  title="" info-tooltip="Add any other +ve or -ve value that need to be applied to adjust the total amount of the transaction Eg. +10 or -10."  style={{ '--tooltip-bg': 'black', 'zIndex': "10px" }}>
  <FontAwesomeIcon icon={faQuestionCircle}  className="word-break break-words mb-0.5 text-xs text-gray-500 mx-1 cursor-pointer" />
</button>
</div>


                </div>
                <div><p className='text-sm '>{amendedPrice ? amendedPrice : '0.00'}</p></div>
                </div>
                <div className='flex justify-start space-x-24 w-3/4 relative'>
      <label htmlFor="insurance" className="block text-sm font-regular">
        Insurance
      </label>
      <input
        type="text"
        id="insurance"
        value={insurance}
        onChange={handleInsuranceChange}
        className='px-2 text-right py-1 w-1/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
        placeholder="Enter insurance amount"
      />
    </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-lg font-semibold '>Total ( ₹ )</p>
                </div>
                <div><p className='text-lg font-semibold'>{formatNumber(total)}</p></div>
                </div>
                </div>
                </div>
              </div>
              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] text-white border border-[#DDDDDD] rounded-md"
          onClick={handleSavePurchaseOrderData} >
            Save as Draft  {isSaving && <Spinner />} 
            </button>
         {/*    <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
        onClick={handleSubmitUomData} >
            Save and Send  {isSaving && <Spinner />} 
            </button> */}
          <button  onClick={handleClose}  className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>

{/* Popup starts */}
      {showDesignSelectPopup && (
      <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col">
        <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
            <h2 className="text-md">Select Design</h2>
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">   
              <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer" />
            </button>
          </div>
          <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />

          {/* <div className="flex flex-wrap p-4 overflow-auto">
            {sampleDesigns.map(design => (
              <div key={design.id} className="w-1/3 p-2">
                <div className={`border rounded-md p-2 ${isSelected(design.id) ? 'border-blue-500' : 'border-gray-300'}`}>
                  <img src={design.imageUrl} alt={design?.name} className="w-full h-32 object-cover rounded-md" />
                  <h3 className="text-center mt-2">{design?.name}</h3>
                  <div className="flex items-center justify-center mt-2">
                    <input 
                      type="checkbox"
                      checked={isSelected(design.id)}
                      onChange={() => handleSelectDesign(design)}
                    />
                    <span className="ml-2">Select</span>
                  </div>
                  {isSelected(design.id) && (
                    <div className='flex justify-between items-center'>
                    <label htmlFor="quantity" className="mt-2 block text-sm font-regular text-center text-gray-700 ">
                      Quantity :
                    </label>
                    <input 
                      type="number" 
                      value={selectedDesigns.find(item => item?.id === design?.id).quantity} 
                      onChange={(e) => handleQuantityChange(design?.id, parseInt(e.target.value) || 0)}
                      className="mt-2 w-1/2 p-1 border rounded-md"
                      min="1"
                    />
                  </div>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          <div className="flex flex-wrap p-4 overflow-auto">
    {SelectedItemRow?.sub_items && SelectedItemRow?.sub_items
      .filter((design) =>
        design.sub_item_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((design) => {
        const isSelected = rows[SelectedItemRowIndex]?.selectedDesigns?.some(
          (d) => d.sub_item_id === design.sub_item_id
        );

        return (
          <div key={design.id} className="w-1/3 p-2">
            <div
              className={`border rounded-md p-2 ${
                isSelected ? "border-blue-500" : "border-gray-300"
              }`}
            >
              <img
                src={imageUrl + design?.sub_item_image}
                alt={design?.sub_item_image}
                className="w-full h-32 object-cover rounded-md"
              />
              <h3 className="text-center mt-2">{design?.sub_item_name}</h3>
              <div className="flex items-center justify-center mt-2">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => handleSelectDesign(design)}
                />
                <span className="ml-2">Select</span>
              </div>
              {isSelected && (
                <div className="flex justify-between items-center">
                  <label
                    htmlFor={`quantity-${design.id}`}
                    className="mt-2 block text-sm font-regular text-center text-gray-700"
                  >
                    Quantity:
                  </label>
                  <input
                    type="number"
                    id={`quantity-${design.id}`}
                    value={
                      rows[SelectedItemRowIndex]?.selectedDesigns?.find((d) => d.sub_item_id === design.sub_item_id)?.sub_item_quantity || ''
                    }
                    onChange={(e) =>
                      handleQuantityChangeForDesign(
                        design.sub_item_id,
                        design.id,
                        parseInt(e.target.value, 10)
                      )
                    }
                    className="w-1/3 p-1 border rounded-md"
                    min="1"
                  />
                  {SelectedItemRow?.UOM}
                </div>
              )}
            </div>
          </div>
        );
      })}
  </div>


          <div className="mt-auto w-full">
            <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
            <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
              <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md">
                Save
              </button>
              <button onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )}

{showDoorsAndWindowsBreakUpPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-3/4 h-2/3 flex flex-col">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
        <h2 className="text-md flex justify-center">Break Up of {selectedProductCategory.label}</h2>
      </div>

      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />

      {/* Table to display dynamic data */}
      <div className="flex-grow overflow-auto p-6">
        {Array.isArray(breakUp) && breakUp.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UIC</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Height</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Width</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Area</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Installation</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {breakUp.map((row, index) => (

                <tr key={index}>
                  {console.log(row)}
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.UIC}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.computedMeasurements.length}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.computedMeasurements.breadth}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.computedMeasurements.areaInSFT}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.itemName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.installation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="px-6 py-4 text-sm text-gray-500">No data available</p>
        )}
      </div>
      <div className='flex justify-end m-5'>
        <button className="px-2 py-1 bg-[#f5f5f5] text-red-700 border border-[#DDDDDD] rounded-md mt-10" onClick={handleExportAsExcel}>Export As Excel</button>
      </div>

      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button onClick={handleCloseDoorsAndWindowsBreakUpPopup} className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md">Done</button>
          <button onClick={handleCloseDoorsAndWindowsBreakUpPopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}
      {showPopup && (
        <div>
           {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
           {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    {/*   pop up end */}

    </div>
  );
};

export default NewPurchaseOrder;
