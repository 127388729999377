import React, {useEffect ,useState} from "react";
import { FaArrowRight, FaImage, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';

const ItemsforQuote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detailedSelection  = location.state?.detailedSelection  || [];
  const {categoryId} = location.state || '';
  const {customerId} = location.state || '';
  const {projectId} = location.state || '';
  console.log(detailedSelection);
  const [localDetailedSelection, setLocalDetailedSelection] = useState(location.state?.detailedSelection || []);
console.log(localDetailedSelection);
const [UomOptions , setUomOptions] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showSelectItemPopup, setShowSelectItemPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [items , setItems] = useState([]);
const [selectedCardItems, setSelectedCardItems] = useState({});
console.log(selectedCardItems);
const [currentCardIndex, setCurrentCardIndex] = useState(null);
console.log(currentCardIndex);
const[ attributes, setAttributes]= useState([])
const { 
  showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
  isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
  setPopupType } = useCommonResponseHandler();
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [subItems,setSubItems] = useState([]);
  const [AlertofSelectingMoreItems , setAlertofSelectingMoreItems] = useState(false);

  const fetchItemsofSelectedGroup = async (productGroupId ,attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product/get-products-by-product-group?product_group_id=${productGroupId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        if (output) {
          console.log(output)
          setItems(output);
        } else {
          setItems([]);
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchItemsofSelectedGroup(productGroupId, attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchItemsofSelectedGroup(productGroupId ,attempt)); // Set retry function
        }
      }/* finally {
        setIsLoading(false);
      } */
    };
    
    useEffect(() => {
      const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          //console.log(data)
          const output = await handleGetResponse(response , data)
          console.log(output)
          if (output) {
          //console.log(output)
           setAttributes(output)
          } else {
            setAttributes([])
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
          }
        }
        }
        if(categoryId){
        fetchAttributesByproductCategory(categoryId);
        }
    }, [categoryId]);


  const handleSelectItems = (cardIndex) => {
    fetchItemsofSelectedGroup(cardIndex.productGroup._id);
    setCurrentCardIndex(cardIndex);
    setSelectedCardItems(prev => ({...prev, [cardIndex]: []}));
    setShowSelectItemPopup(true);
  };
  
  const handleConfirm = () => {
    setShowSelectItemPopup(false);
    setShowConfirmPopup(true);
  };

  const handleClickOkay = () => {
    setAlertofSelectingMoreItems(false);
  }

      const handleItemSelection = (item) => {
        setSelectedCardItems(prev => {
          const updatedItems = {
            ...prev,
            [currentCardIndex]: [item]
          };

          setLocalDetailedSelection(prevSelection =>
            prevSelection.map((selection) =>
              selection.area === currentCardIndex.area ? {
                ...selection,
                selectedItem: item,
                productGroup: {
                  ...selection.productGroup,
                  item_id: item.item_id,
                  item_name: item.item_name
                }
              } : selection
            )
          );

          return updatedItems;
        });
      };

      useEffect(() => {
        FetchUoms();
    }, []);
    
      const FetchUoms = async (attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          if (output) {
            // Log the data to ensure it's fetched correctly
            //console.log('Fetched uoms:',output);
      
            const uomData = output.map((item) => ({
              id: item._id,
              value: item.UOM,
              label: item.UOM,
            }));
      console.log(data);
        setUomOptions(uomData)
          } else {
            console.error('Error:', data.message);
            return ([])
    
          }
        }catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => FetchUoms(attempt + 1), 1000); // Retry after 1 second
          } else {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => FetchUoms(attempt)); // Set retry function
          }
        }
      };

      const findMatchingUOM = (uomId) => {
        const matchedUOM = UomOptions.find(uom => uom.id === uomId);
        return matchedUOM ? matchedUOM.value : ' '; // Default to 'Box' if no match found
      };

      const handleSaveSalesOrder = async () => {
        try {
          const token = sessionStorage.getItem('token');  
          // Prepare the items array with async operations for calculating quantity and prices
          const items = await Promise.all(localDetailedSelection.map(async (selection) => {
            const quantity = handletheQuantity(selection.value, selection.productGroup); // Calculate quantity
            const cost = calculeteCost(quantity, selection.productGroup?.selling_price); // Calculate cost based on quantity and selling price
            const matchedUOM = findMatchingUOM(selection.productGroup.UOM_id);
            return {
              item_id: selection?.selectedItem.item_id,
              item_name: selection.selectedItem.item_name,
              quantity: quantity, // Use calculated quantity
              cost_price: selection.productGroup.cost_price, // Set cost price
              selling_price: selection.productGroup.selling_price, // Set selling price
              tax_rate: selection.productGroup.GST, // Use product GST rate
              tax_type: 'GST', // Assuming tax type is always GST
             cost_price: selection.selectedItem.cost_price,
             UOM_id: selection.selectedItem.UOM_id,
              UOM: 'Box',
              total_tax_amount: calculateItemTax(selection.productGroup.GST, cost), // Call the function to calculate tax
              sales_total: cost, // Set sales total to calculated cost
              UOM_id: selection.selectedItem.UOM_id, // Set UOM ID
              UOM: matchedUOM || '', // Set UOM name
              application_areas: {
                area_name: selection.area, // Set area name
                area: selection.value.toString(), // Convert area value to string
                count: '', // Add count logic if needed
              },
              organizationId:sessionStorage.getItem('organizationId'),  

            };
          }));
      
          // Calculate subtotal by summing up all item sales_totals
          const subtotal = calculateSalesTotalOfEachLineItem(items);
          const taxAmount = calculateSaleTaxAmount(items);
          
          // Prepare sales order data
          const salesorderData = {
            customer_id: customerId,
            product_category_id: categoryId,
            salesorder_date: new Date().toISOString(),
            project_id: projectId,
            status: 'Confirmed',
            subtotal: parseFloat(subtotal), // Use calculated subtotal
            tax_amount: taxAmount, // Use calculated tax amount
            total : parseFloat(subtotal) + parseFloat(taxAmount), // Calculate total
            tax_prefrence: 'Tax Exclusive',
            organizationId:sessionStorage.getItem('organizationId'),  
          };
      
          console.log({ ...salesorderData, items });
      
          // Make the API call to create the sales order
          const response = await fetch(`${fetchUrl}customer/salesorder/create-salesorder`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token, 
            },
            body: JSON.stringify({ ...salesorderData, items }), // Combine sales order data and items
          });
      console.log(salesorderData)
          const data = await response.json();
      console.log(data)
          if (response.ok) {
            // Handle success (navigate to a new page or show success message)
            console.log('Sales order created successfully:', data);
            navigate('/consumer/app');
          } else {
            // Handle error response from the server
            console.error('Error creating sales order:', data);
            // Show error message to user
          }
        } catch (error) {
          // Handle general error
          console.error('Error:', error);
          // Show error message to user
        }
      };
      
      // Corrected function to calculate total tax amount
      const calculateSaleTaxAmount = (items) => {
        return items.reduce((totalTax, element) => {
          const taxAmount = parseFloat(element.total_tax_amount || 0); // Ensure value is a number
          return totalTax + taxAmount;
        }, 0);
      };
      
      // Corrected function to calculate the total sales of each line item
      const calculateSalesTotalOfEachLineItem = (items) => {
        return items.reduce((total, element) => {
          const salesTotal = parseFloat(element.sales_total || 0); // Ensure value is a number
          return total + salesTotal;
        }, 0);
      };
      
      // Corrected tax calculation function
      const calculateItemTax = (GST, cost) => {
        const lineItemTax = (parseFloat(GST) / 100) * parseFloat(cost);
        return lineItemTax;
      };
      
      
   
      const handletheQuantity = ( value , productGroup) => {
  
        const productGroupData = productGroup;
        console.log('productGroupData', productGroupData)
        const attributeValueIds = productGroupData?.attribute_value_ids || [];
        const sqFtPerBoxAttribute = attributes.find(p => p.attribute_name === 'Sq Ft per Box');
        
        if (sqFtPerBoxAttribute) {
          let conversionFactor = 0;
    
          for (const valueId of attributeValueIds) {
            const attributeValue = sqFtPerBoxAttribute.attribute_values.find(p => p.attribute_value_id === valueId);
            if (attributeValue) {
              conversionFactor = parseFloat(attributeValue.attribute_value);
              break;
            }
          }
    
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
            console.log('quantity', quantity);
            return quantity;
          }
        }
      
      return 0; // Return 0 if no valid conversion can be made
    };
    
    const calculeteCost = (Boxes, price) => {
      // Convert Boxes and price to numbers and handle undefined or invalid values
      const numBoxes = parseFloat(Boxes) || 0; // Default to 0 if Boxes is not a valid number
      const numPrice = parseFloat(price) || 0; // Default to 0 if price is not a valid number
    
      const totalPrice = numBoxes * numPrice;
      return totalPrice.toFixed(2); // Ensure it returns a string with two decimal places
    };
      
  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      <h1 className="text-2xl font-bold mb-4">Select Items for Order</h1>         
          {localDetailedSelection.map((tile, index) => (
            <div key={index} className="border shadow-sm bg-white rounded-md p-4 mb-4">
               <div className="flex justify-between items-center mb-2">
    <h3 className="font-bold">{tile.area}</h3>
    <p className="text-sm text-gray-600">Area: {tile.value} sq.ft</p>
    {attributes.length > 0 &&<p className="text-sm text-gray-600">Quantity: {handletheQuantity(tile.value , tile.productGroup)} Box</p>}
  </div>
              <div className="flex justify-between items-center">
  <h4 className="font-semibold">{tile.productGroup.product_group_name}</h4>
  <p>Price: Rs.{tile.productGroup.selling_price} /Box</p>
</div>
<div className="border-t border-gray-300 mt-2 flex justify-between" onClick={() => handleSelectItems(tile ,index)}>
<span className="mt-2">
  {localDetailedSelection[index].selectedItem
    ? localDetailedSelection[index].selectedItem.item_name
    : "Choose Products"}
</span>

  <FaArrowRight className="text-gray-600 mt-3" />
</div>
            </div>
          ))}
          <div className="mt-6 flex flex-col justify-center items-center space-y-2">
          {/* <p>Sub Total: Rs. {calculateSubtotal().toFixed(2)}</p>
        <p>Tax : Rs. {calculateTaxAmount().toFixed(2)} (Exclusive)</p>
        <p>Total: Rs. {calculateTotal().toFixed(2)}</p> */}

      </div>
<div className="mt-4 flex justify-center items-center">
  <button 
    className="border border-[#EC001D] text-black px-6 py-1 rounded"
    onClick={handleSaveSalesOrder} >
    Confirm
  </button>
</div>

     {/*  {popup starts}  */}
       {showSelectItemPopup && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="bg-white w-full h-full p-6 overflow-y-auto">
    <div className="flex justify-between items-center mb-4">
  <h2 className="text-xl font-bold">Select Items</h2>
  <button
    onClick={() => setShowSelectItemPopup(false)}
    className="text-2xl font-bold"
  >
    <FaTimes className="text-[#EC001D] w-4 h-4" />
  </button>
</div>    
      <div className="grid grid-cols-2 gap-4">
      {items.map((item) => {
  const isSelected = detailedSelection[currentCardIndex]?.selectedItem?.item_id === item.item_id;
  return (
      <div 
        key={item.item_id} 
        className={`border rounded-lg p-4 flex flex-col items-center ${isSelected ? 'bg-gray-200' : ''}`}
      >
        <div className="w-24 h-24 flex items-center justify-center">
  {item.item_image ? (
    <img 
      src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}/${item.item_image}`} 
      alt="image unavailable"
      className="w-full h-full object-cover"
    />
  ) : (
    <FaImage className="text-gray-400 w-12 h-12" />
  )}
</div>
        <p className="text-center mb-2">{item.item_name}</p>
        <button
        className={`px-4 py-2 rounded ${isSelected ? 'border border-[#EC001D] bg-[#EC001D] text-white' : 'border border-[#EC001D]'} text-black`}
        onClick={() => handleItemSelection(item, currentCardIndex)}>
        {isSelected ? 'Selected' : 'Select'}
      </button>
      </div>
    );
  })}
  
</div>
<div className="mt-6 flex justify-center items-center">
  <button 
    className="border border-[#EC001D] text-black px-6 py-1 rounded"
    onClick={() => {
      setShowSelectItemPopup(false);
      }} >
    Submit
  </button>
</div>
</div>
</div>
)}

{showConfirmPopup && (
 <div className="fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center">
 <div className="bg-white p-6 rounded-lg">
 <div className="flex justify-end items-end">
 <button
    onClick={() => setShowConfirmPopup(false)}
    className="text-2xl font-bold"
  >
    <FaTimes className="text-[#EC001D] w-4 h-4" />
  </button>
  </div>
   <h2 className="text-xl font-bold mb-4">Confirmed!!</h2>
   <p>Now your Sales Order is confirmed.</p>
   <p></p>
   <div className="flex justify-between mt-4">
   <button
       className="text-black font-bold py-1 px-4 rounded border border-[#EC001D]"
      onClick={handleClickOkay}
     >
       Okay
     </button>
   
     </div>
 </div>
</div>
)}

{AlertofSelectingMoreItems &&  (
 <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
 <div className="bg-white p-6 rounded-lg">
   <p>Only one item can be selected. Please deselect the current item to choose another.</p>
   <p></p>
   <div className="flex justify-between mt-4">
   <button
       className="text-black font-bold py-1 px-4 rounded border border-[#EC001D]"
      onClick={handleClickOkay}
     >
       Okay
     </button>
   
     </div>
 </div>
</div>
)}

       {/*  {popup ends}  */}
    </div>
  );
};

export default ItemsforQuote;
