import React, { useState, useEffect, useMemo  } from "react";
import { useLocation , useNavigate } from "react-router-dom";
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../BBAdmin/Messages/Messages'
import { Loader ,Spinner } from '../BBAdmin/Loader/Loader';
import { jwtDecode } from "jwt-decode";
import { FaBox, FaRuler, FaInfoCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';




const TilesQuote = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('project');
  const categoryId = searchParams.get('category');
  const customerId = searchParams.get('customer');
  const[ attributes, setAttributes]= useState([])
  const [RawMeasurements , setRawMeasurements] = useState([]);
  const [measurementsData, setMeasurementsData] = useState([]);
  const [Productgroups , setProductGroups] = useState([]);
  const areas = ["Living", "Dining", "Kitchen", "Bedroom"];
  const areas2 = ["Bathroom", "Kitchen", "Washarea"];
  const areas3 = ["Bathroom", "Washarea", "Balcony"];
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const fetchUrl = process.env.REACT_APP_FORE_APILINK;
    const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
    const urlToken = searchParams.get('token');
    const [expandedGroups, setExpandedGroups] = useState({});

  const sortedProductGroups = useMemo(() => {
    return Productgroups.map(group => ({
      ...group,
      sortOrder: Object.values(selectedGroups).some(sg => sg._id === group._id) ? 0 : 1
    })).sort((a, b) => a.sortOrder - b.sortOrder);
  }, [Productgroups, selectedGroups]);

  const toggleGroups = (measurementKey) => {
    setExpandedGroups(prev => ({
      ...prev,
      [measurementKey]: !prev[measurementKey]
    }));
  };

    useEffect(() => {
      if (urlToken) {
        try {
          // Decode the token
          const decodedToken = jwtDecode(urlToken);
          
          // Set session data
          sessionStorage.setItem('token', urlToken);
          sessionStorage.setItem('userId', decodedToken.userId);
          sessionStorage.setItem('username', decodedToken.username);
          sessionStorage.setItem('roleId', decodedToken.roleId);
          sessionStorage.setItem('organizationId', decodedToken.organizationId);
          
        } catch (error) {
          console.error('Error decoding token:', error);
          // Handle error (e.g., redirect to error page)
        }
      }
    }, [urlToken, searchParams]);


    useEffect(() => {
    fetchMeasurements();
    fetchProductGroups();
  }, [projectId, categoryId]);

  const fetchMeasurements = async (attempt = 1) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}/project-reports/get-consolidated-measurements-by-project-and-product-category?projectId=${projectId}&productCategoryId=${categoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      if (output) {
        console.log(output);
        setRawMeasurements(output);
        const combineMeasurements = (data, keys) => {
          return data
            .filter(item => keys.includes(item.key))
            .reduce((sum, item) => sum + item.value, 0);
        };
        
        const measurements = output;
        const combinedMeasurements = [
          {
            key: "Living, Kitchen, Dining, Bedroom - Flooring",
            value: combineMeasurements(measurements, [
              "Living Room - Flooring",
              "Kitchen - Flooring",
              "Dining - Flooring",
              "Bedrooms - Flooring"
            ])
          },
          {
            key: "Bathroom, Kitchen, Washarea, Other - Walls",
            value: combineMeasurements(measurements, [
              "Bathrooms - Walls",
              "Kitchen - Walls",
              "Washarea - Walls",
              "Other - Walls"
            ])
          },
          {
            key: "Bathroom, Washarea, Balcony - Flooring",
            value: combineMeasurements(measurements, [
              "Bathrooms - Flooring",
              "Washarea - Flooring",
              "Balcony - Flooring"
            ])
          }
        ];
        
        setMeasurementsData(combinedMeasurements);        
       // console.log(combinedMeasurements);
       } else {
        if(response.status === 500){
          console.log('retry enterd')
          
          setRetryFunction(() => () => fetchMeasurements); 
        }
        console.error(data.message);
      }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchMeasurements(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchMeasurements(attempt)); // Set retry function
      }
    }finally {
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl2}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        //console.log(data)
        const output = await handleGetResponse(response , data)
        console.log(output)
        if (output) {
        //console.log(output)
         setAttributes(output)
        } else {
          setAttributes([])
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
        }
      }
      }
      if(categoryId){
      fetchAttributesByproductCategory(categoryId);
      }
  }, [categoryId]);

  const fetchProductGroups = async (attempt = 1) => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await fetch(
        `${fetchUrl2}product-groups/get-productGroups-by-productCategory?product_category_id=${categoryId}&organizationId=${sessionStorage.getItem('organizationId')}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        }
      );
  
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      //console.log(output)
      if (output) {
        setProductGroups(output);
      }
      // Handle the fetched data
    }catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductGroups(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductGroups(attempt)); // Set retry function
      }
    }
  };


  const tilesData = [
    {
      id: 'flooring',
      areas: areas,
      tiles: [
        { id: '800x1600', name: 'Krafto 800x1600 Glossy', size: '800mm x 1600mm', price: 'XX.XX' },
        { id: '600x1200', name: 'Krafto 600x1200 Glossy', size: '600mm x 1200mm', price: 'XX.XX' },
        { id: '600x600', name: 'Krafto 600x600 Matt', size: '600mm x 600mm', price: 'XX.XX' },
      ]
    },
    {
      id: 'wallTiles',
      areas: areas2,
      tiles: [
        { id: '800x1600', name: 'Krafto 800x1600 Glossy', size: '800mm x 1600mm', price: 'XX.XX' },
        { id: '600x1200', name: 'Krafto 600x1200 Glossy', size: '600mm x 1200mm', price: 'XX.XX' },
        { id: '600x600', name: 'Krafto 600x600 Matt', size: '600mm x 600mm', price: 'XX.XX' },
      ]
    },
    {
      id: 'otherFlooring',
      areas: areas3,
      tiles: [
        { id: '800x1600', name: 'Krafto 800x1600 Glossy', size: '800mm x 1600mm', price: 'XX.XX' },
        { id: '600x1200', name: 'Krafto 600x1200 Glossy', size: '600mm x 1200mm', price: 'XX.XX' },
        { id: '600x600', name: 'Krafto 600x600 Matt', size: '600mm x 600mm', price: 'XX.XX' },
      ]
    }
  ];

/*   const handleGetQuote = () => {
    navigate("/consumerapp/viewtilesquote", { state: { selectedTiles } });
  } */

  const handleTileSelection = (e, tileData, areaInfo) => {
    if (e.target.checked) {
      setSelectedTiles([...selectedTiles, { 
        ...tileData, 
        areas: areaInfo.areas,
        areaType: areaInfo.id === 'wallTiles' ? 'Wall Tiles' : 'Flooring',
        totalArea: areaInfo.id === 'flooring' ? '1500' : areaInfo.id === 'wallTiles' ? '500' : '300'
      }]);
    } else {
      setSelectedTiles(selectedTiles.filter(tile => tile.id !== tileData.id));
    }
  };


const handleProductGroupSelection = (measurementKey, productGroup) => {
  setSelectedGroups(prev => ({
    ...prev,
    [measurementKey]: productGroup
  }));
};

const [RequiredBoxesToArea , setRequiredBoxesToArea] = useState([])

useEffect(() => {
  let RequiredBoxesToArea = [];

  measurementsData.forEach((measurement) => {
    const Boxes = handletheQuantity(measurement.key, measurement?.value);
    console.log('boxes', Boxes)
    const measurementKey = measurement.key;
    const data = {
      [measurementKey]: Boxes
    };
    RequiredBoxesToArea.push(data);
    console.log('area', RequiredBoxesToArea)
  });

setRequiredBoxesToArea(RequiredBoxesToArea);
  // You can now use RequiredBoxesToArea as needed, e.g., set it to state

}, [selectedGroups, measurementsData]);


const handletheQuantity = (key, value) => {
  
    const productGroupData = selectedGroups[key] || {};
    console.log('productGroupData', productGroupData)
    const attributeValueIds = productGroupData?.attribute_value_ids || [];
    const sqFtPerBoxAttribute = attributes.find(p => p.attribute_name === 'Sq Ft per Box');
    
    if (sqFtPerBoxAttribute) {
      let conversionFactor = 0;

      for (const valueId of attributeValueIds) {
        const attributeValue = sqFtPerBoxAttribute.attribute_values.find(p => p.attribute_value_id === valueId);
        if (attributeValue) {
          conversionFactor = parseFloat(attributeValue.attribute_value);
          break;
        }
      }

      if (conversionFactor > 0) {
        const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
        return quantity;
      }
    }
  
  return 0; // Return 0 if no valid conversion can be made
};


const mapCombinedToIndividual = (combinedKey, selectedGroup) => {
  const individualKeys = {
    "Living, Kitchen, Dining, Bedroom - Flooring": ["Living Room - Flooring", "Kitchen - Flooring", "Dining - Flooring", "Bedrooms - Flooring"],
    "Bathroom, Kitchen, Washarea, Other - Walls": ["Bathrooms - Walls", "Kitchen - Walls", "Washarea - Walls" , "Other - Walls"],
    "Bathroom, Washarea, Balcony - Flooring": ["Bathrooms - Flooring", "Washarea - Flooring", "Balcony - Flooring"]
  };

  return individualKeys[combinedKey].map(key => ({
    area: key,
    value: RawMeasurements.find(m => m.key === key)?.value || 0,
    productGroup: selectedGroup
  }));
};

const handleGetQuote = () => {
  const detailedSelection = Object.entries(selectedGroups).flatMap(([key, group]) => 
    mapCombinedToIndividual(key, group)
  );
console.log(detailedSelection);
  navigate("/consumer/app/viewtilesquote", { state: { detailedSelection , categoryId: categoryId , projectId:projectId , customerId: customerId  }  });
};




  
  if (isLoading) {
    return <Loader />;
  }

  return (
<div className="bg-[#F0F4F8] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
  <h1 className="text-4xl font-bold mb-6 text-center text-[#2C3E50]">Select Your Tiles</h1>
  
  {measurementsData.map((measurement, index) => {
    const isExpanded = expandedGroups[measurement.key];
    const selectedGroup = selectedGroups[measurement.key];


    return (
      <div key={index} className="bg-white rounded-lg shadow-lg border border-[#BDC3C7] p-6 mt-2">
        <div className="flex flex-col items-start border-b border-[#ECF0F1] mb-4 pb-4">
          <div className="flex items-start text-xl font-semibold mb-3">
            <FaInfoCircle className="flex-shrink-0 mt-1 mr-2 text-[#3498DB]" />
            <span className="text-[#3498DB]">{measurement?.key}</span>
          </div>
          
          <div className="text-lg text-[#2C3E50] mb-2">
            <FaRuler className="inline mr-2 text-[#E74C3C]" />
            Area: <span className="font-bold">{measurement?.value.toFixed(2)}</span> <span className="font-semibold">sq.ft</span>
          </div>
          
          <div className="text-lg text-[#2C3E50]">
            <FaBox className="inline mr-2 text-[#27AE60]" />
            Quantity: <span className="font-bold">
              {RequiredBoxesToArea.find(p => p[measurement?.key])?.[measurement?.key] || 0}
            </span> <span className="font-semibold">Box</span>
          </div>
        </div>
        
        <div className="bg-[#ECF0F1] p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-4 text-[#2C3E50]">Choose a Product Group:</h3>
          {selectedGroup && (
          <div className="mb-4 p-3 bg-[#D6EAF8] rounded-lg">
            <p className="font-semibold">Selected: {selectedGroup.product_group_name}</p>
          </div>
        )}
          <div className={`max-h-[${isExpanded ? '600px' : '300px'}] overflow-y-auto mb-4 transition-all duration-300`}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {sortedProductGroups.map((PG, pgIndex) => (
                <div
                  key={PG._id}
                  className={`p-4 rounded-lg border cursor-pointer shadow-md transition-all duration-200 ${
                    selectedGroups[measurement.key]?._id === PG._id
                      ? 'bg-[#3498DB] text-white border-[#2980B9]'
                      : 'bg-white text-[#2C3E50] border-[#BDC3C7] hover:bg-[#F7F9FA]'
                  }`}
                  onClick={() => handleProductGroupSelection(measurement.key, PG)}
                >
                  <h3 className="font-semibold mb-2 text-lg">{PG.product_group_name}</h3>
                  <p className={`text-lg ${selectedGroups[measurement.key]?._id === PG._id ? 'text-white' : 'text-[#27AE60]'}`}>
                    ₹{PG.selling_price}/<span className="text-sm">Box</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
          {sortedProductGroups.length > 3 && (
            <div className="text-center">
              <button
                onClick={() => toggleGroups(measurement.key)}
                className="bg-[#A5ADC4] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#A5ADC4] transition-colors duration-200"
              >
                {isExpanded ? (
                  <>Show Less <FaChevronUp className="inline ml-2" /></>
                ) : (
                  <>Show More <FaChevronDown className="inline ml-2" /></>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  })}
  
  <div className="mt-8 flex justify-center">
    <button
      className="w-2/3 md:w-1/2 lg:w-1/3 text-white font-semibold py-3 px-6 rounded-lg bg-[#E74C3C] hover:bg-[#C0392B] transition-colors duration-200 text-xl shadow-lg"
      onClick={handleGetQuote}
    >
      Get Your Quote
    </button>
  </div>
</div>

  

  );
};

export default TilesQuote;
