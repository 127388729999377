import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faTrash, faExclamationTriangle, faX, faPlusCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import Dropdown from './DropDown'
import TableSearchDropDown from '../Components/tableSerchDropDown';
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (value , id) => {
    onSelect(value , id);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) { 
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (!isOpen) { 
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption.value, selectedOption.id);
        }
        break;
      default:
        break;
    }
  };

  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);


  return (
    <div className="relative w-1/3" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isOpen}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option,index) => (
              <div
                key={option.value}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option.value, option.label,option.id)}
              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
        {showAddNew && (
          <button
          type="button"
          className="flex items-center border-t p-2 mt-2 text-blue-500 "
          onClick={showAddNew}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
           New UOM
        </button>
        )}
      </div>
    )}
  </div>
  );
};


const NewProductCategory = () => {
  const navigate = useNavigate();
  const [ProductSubCategory, setProductSubCategory] = useState([{  product_sub_category_name: '', UOM_type: '', description: '', UOM_id: '' } ]);
  const [product_category_name,setProductCategoryName]= useState('')
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [showNewUOMPopup, setShowNewUOMPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUOM, setSelectedUOM] = useState('');
  const [SelectdPCUomid,setSelectdPCUomid] = useState()
  const [UOM, setUOM] = useState('')
  const [description,setdescription]= useState('')
  const [errorMessages, setErrorMessages] = useState([]);
  const [PCErrorMessage , setPCErrorMessage] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
  const [selectedUomData , setselectedUomData] = useState()
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET
   const [UomName,setUomName] = useState('')
   const [UomIndex,setUomIndex] = useState()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [ProductCategoryDescription, setProductCategoryDescription]= useState('');
  const [showCancelPopup, setShowCancelPopup] = useState(false);
 const [uom,setUom]= useState()
 const[ uomOptions ,setUomOptions]= useState([
 
]);
const [DropDownUoms,setDropDownUoms]= useState([])
  const handleDropdownChange = (value ,label, id) => {
    setErrorMessages([]);
    if (value === 'add-new') {
      setShowNewUOMPopup(true);
    } else {
      setSelectedUOM(value);
      setSelectdPCUomid(id)
    }
  };



  const handleClosePopup = () => {
    setShowNewUOMPopup(false);
    setUOM('');
    setdescription('');
    setErrorMessages([]);
    setPCErrorMessage([]);
  };



  const deleteRow = (index) => {
    const updatedData = [...ProductSubCategory];
    updatedData.splice(index, 1);
    setProductSubCategory(updatedData);
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...ProductSubCategory].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setProductSubCategory(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

 

  const handleSubmitUomData = async () => {
    const errorMessages = [];
    if (!UOM) {
      errorMessages.push('Please enter UOM');
    }
    if (errorMessages.length > 0) {
      setErrorMessages(errorMessages);
      return;
    }
    setIsSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const saveUomUrl = `${fetchUrl}UOMs/create-UOM`;
      const requestData = {
        UOM: UOM,
        UOM_description: description,
      };
      console.log(requestData);
      const response = await fetch(saveUomUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(requestData),
      });
      const Data = await response.json();
      if (Data.status === 200 || Data.status === 201) {
        setIsSaving(false);
        setPopupMessage('UOM created successfully');
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        setShowNewUOMPopup(false);
        setUOM('');
        setdescription('');
        getUoms();
      } else {
        setIsSaving(false);
        setPopupMessage(Data.message);
        setPopupType(`${Data.status}`);
        setShowPopup(true);
        setUOM('');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSaving(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
    }
  };
  

  const getUoms = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const getUomUrl = `${fetchUrl}UOMs/get-all-UOMs`;
  
      const response = await fetch(getUomUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output= await getResponseHandling(response, data)
      console.log(output);
      if (output) {
       
        const AllUoms= []
        for ( const uom of data.data){
          const uomData= {
            label: uom.UOM, value: uom.UOM , id: uom._id
          }
          AllUoms.push(uomData)
          console.log(uomData);
        }
        const addUomOption = []
        const UOMS= [...AllUoms , ...addUomOption]
        setDropDownUoms(AllUoms)
        setUomOptions(UOMS)
      } else {
        console.error('Error fetching UOMs');
      }
    } catch (error) {
      console.error('Failed to fetch UOMs:', error);
    }
  };

  useEffect(() => {
    getUoms();
  }, []);
  
  const getResponseHandling = async (response, data)=>{
    if (response.status === 200 || response.status === 201) {
      setPopupType('200or201');
        return (data.data)
    } else if (response.status === 204) {
      setPopupType('204');
      return (data.data)
    } 
 
    if (response.status === 400) {
      setPopupType('400');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else if (response.status === 401) {
      setPopupType('401');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else if (response.status === 403) {
      setPopupType('403');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else if (response.status === 404) {
      return null
    }    else if (response.status === 500) {
     
      setPopupType('500');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else {
      setErrorMessages([...errorMessages]);
    }
  
}

  const handleProductSubCategoryChange = (e, index, field) => {
    const updatedData = [...ProductSubCategory];
    updatedData[index][field] = e.target.value;
    setProductSubCategory(updatedData);

    if (field === 'UOM_type') {
      setUomName(e.target.value);
      setIsDropdownVisible(true);
      setUomIndex(index);
    }
  };

  const HandleChangeUom = (option , index) => {
    const updatedData = [...ProductSubCategory];
    console.log(updatedData)
    if (index !== null) {
      updatedData[index].UOM_type = option.label;
      updatedData[index].UOM_id = option.id; // Ensure this is the correct value for ID
    }
    setProductSubCategory(updatedData);
    setIsDropdownVisible(false);
  };
  

   const addRow = () => {
    setUomIndex()
    const newRow = {  product_sub_category_name: '', UOM_type: '', product_sub_category_description: '', UOM_id: '' };
    setProductSubCategory([...ProductSubCategory, newRow]);
  };

  const handleInputFocus = (index) => {
    setIsDropdownVisible(true);
    setUomIndex(index);
  };

  const handleSubmitProductCategory = async () => {
  const data = {
    product_category_name: product_category_name,
    UOM_id:SelectdPCUomid,
    product_category_description:ProductCategoryDescription,
    product_sub_categories: ProductSubCategory  }
    const PCErrorMessage = [];
    if (!data.product_category_name) {
      PCErrorMessage.push('Item Category Name is Mandatory');
      
    }
    if (PCErrorMessage.length > 0) {
      setPCErrorMessage(PCErrorMessage);
      return;
    }
    setIsSaving(true);
     try {
      const token = sessionStorage.getItem('token');
      const saveUomUrl = `${fetchUrl}product-category/create-productCategory`;
      const response = await fetch(saveUomUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(data),
      });
      const Data = await response.json();
      console.log(Data);
      if (Data.status === 200 || Data.status === 201) {
        setIsSaving(false);
        setPopupMessage("Item Category created successfully");
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/items/productcategorydetails', { state: { ProductCategoryData: Data.data } });
        }, 3000);
      } else if (Data.status === 204) {
        setIsSaving(false);
        setPopupMessage("Product Category created successfully");
        setPopupType('204');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/items/productcategorydetails', { state: { ProductCategoryData: Data.data } });
        }, 3000);
      } else {
        setErrorMessages([]);
        setPCErrorMessage([]);
        setIsSaving(false);
        setPopupMessage(Data.message);
        setPopupType(`${Data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSaving(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
    }finally{
      setErrorMessages([]);
      setPCErrorMessage([]);
    }
  }

  const handleCloseError = () => {
    setErrorMessages([]);
    setPCErrorMessage([]);
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = () => {
    console.log("Retry called");
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleCancel = () => {
    setShowCancelPopup(true);
  }

  const handleLeave = () => {
    navigate(-1);
  }

  return (
    <div className=" bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
         <div className="flex flex-row w-full"> 
          <div className="w-full flex-1">
            <div className="flex flex-col w-full h-full justify-center items-center">
              <div className="w-full h-full flex flex-col justify-between ">
              <div className="w-full h-16 border-b flex items-center">
  <div className="flex w-full justify-between px-4 items-center">
    <h1 className="text-lg font-semibold">New Item Categories</h1>
  </div>
</div>
              {PCErrorMessage.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {PCErrorMessage.map((PCErrorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {PCErrorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}
                <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                  <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                    {/* Product Category Name Label and Input */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Item Category Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="productCategoryName"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                       value={product_category_name}
                       onChange={(e)=>setProductCategoryName(e.target.value)}
                      />
                    </div>

                    <div className="w-full flex flex-row items-center mb-4 space-x-2">
                      <label htmlFor="uom" className="block text-sm font-regular text-gray-700 w-1/6">UOM</label>
                      <CustomDropdown options={uomOptions} selected={selectedUOM} onSelect={handleDropdownChange}
                      showAddNew={() => setShowNewUOMPopup(true)} />
                    </div>
      
                    {/* Description Label and Textarea */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2">
                      <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
                      <textarea
                        id="Description"
                        name="Description"
                        className="mt-1 w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={ProductCategoryDescription}
                        onChange={(e)=>setProductCategoryDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-start items-start mb-4 mt-4 px-4">
                    <h1 className="text-md font-semibold">Item Sub-Categories</h1>
                  </div>
                  <div className="w-full container mx-auto px-4 mb-2 mt-4">
                  {ProductSubCategory.length > 0 ? (

           <table className="w-full">
                      <thead>
                        <tr>

                          <th className="text-xs font-medium py-2 px-4 border-t border-r border-gray-200 cursor-pointer" onClick={() => sortArray('productSubCategory')}>
                            ITEM SUB-CATEGORY NAME {getSortIcon('productSubCategory') && <FontAwesomeIcon icon={getSortIcon('productSubCategory')} />}
                          </th>
                          <th className="text-xs font-medium py-2 px-4 border-t border-r border-gray-200 cursor-pointer" onClick={() => sortArray('uom')}>
                            UOM {getSortIcon('uom') && <FontAwesomeIcon icon={getSortIcon('uom')} />}
                          </th>
                          <th className="text-xs font-medium py-2 px-4 border-t border-gray-200 cursor-pointer" onClick={() => sortArray('description')}>
                            DESCRIPTION {getSortIcon('description') && <FontAwesomeIcon icon={getSortIcon('description')} />}
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
          {ProductSubCategory.map((productsubcategory, index) => (
       <tr key={index} className="text-sm border-t-gray-200 border-b-gray-200 border-l-0 border-r-0 ">
       <td className="border-t border-r border-b border-gray-200">
        
           <input
             type="text"
             value={productsubcategory?.product_sub_category_name}
             onChange={(e) => handleProductSubCategoryChange(e, index, 'product_sub_category_name')}
             className="outline-blue-500 text-sm w-full bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 p-4"
           />
      
       </td>
       <td className="border w-40">
  
    <TableSearchDropDown
      options={DropDownUoms} // Pass your options here
      value={productsubcategory?.UOM_type} // Pass the value (ID) of the selected UOM
      onChange={(selectedOption) => HandleChangeUom(selectedOption , index)} // Handle change
      index={index} // Pass the index
      getFilteredOptions={(options, inputValue) => options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )}
      setEditUOM={(value) => setUomName(value)}
    />

</td>

       
       <td className="border-t border-b border-gray-200 ">
         
           <textarea
             value={productsubcategory?.product_sub_category_description}
             onChange={(e) => handleProductSubCategoryChange(e, index, 'product_sub_category_description')}
             placeholder="Description"
             className="outline-blue-500 text-sm px-4 w-full h-full bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
           />
        
       </td>
       <td className="py-2 px-4 text-center">
         <button
           onClick={() => deleteRow(index)}
           className="text-red-500 hover:text-red-700 focus:outline-none"     >
           <FontAwesomeIcon icon={faTrash} />
         </button>
       </td>
     </tr>
          ))}
        </tbody>
                    </table>
  ) : (
    <div className="text-center py-4">
    <p>No Sub Categories </p>
    </div>
  )}
                
                
{ /* Button to add another row */}
          <div className="mt-4">
                    <button   onClick={addRow}   className="text-blue-500 py-2 px-4"  >
                      <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                      Add Item Sub-Category     </button>
                  </div>
                  </div>
                  </div>
                  <div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
          onClick={handleSubmitProductCategory}>
           <span>Save</span>
            {isSaving && ( <Spinner/>)}
            </button>
          <button onClick={handleCancel}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
        </div>
        </div>
                </div>
              </div>  
          </div>
         </div> 

         {/* Popup starts */}

        {showNewUOMPopup && (
  <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col animate-slide-in ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">New UOM</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {errorMessages.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-6">
        <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">
          UOM<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="productCategoryName"
          className="w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={UOM}
          onChange={(e)=>{setUOM(e.target.value)}}
       />
      </div>
      {/* Description Label and Textarea */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 px-6">
        <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
        <textarea
          id="Description"
          name="Description"
          className="mt-1 w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={description}
          onChange={(e)=>{setdescription(e.target.value)}}
        />
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
          onClick={handleSubmitUomData}>
           <span>Save</span>
            {isSaving && <Spinner />}
            </button>
          <button onClick={handleClosePopup}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
        Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* Popup ends */}

      </div>
  
  );
};

export default NewProductCategory;
