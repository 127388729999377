import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronDown, faChevronUp, faSortUp, faSortDown, faX, faPaperclip,faFileImport, faPlus, faPen, faMobileAndroidAlt, faPlusCircle, faSave, faTimes, faTrash, faComputer, faLaptopFile, faCertificate, faRegistered, faRectangleList, faCashRegister, faVcard, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import ContactPersons from '../Components/ContactPersons';
import  '../Css/Tooltip.css';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className="relative w-1/6" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Salu'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>

    {isOpen && (
      <div className="absolute mt-1 w-full h-40 bg-white border border-gray-300 rounded-md shadow-lg z-10 flex flex-col">
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {options.map((option) => (
            option.value !== 'add-new' && (
              <div className='p-1'>
              <div
                key={option.value}
                className="py-2 pl-1 cursor-pointer text-sm hover:bg-blue-500 hover:rounded-md  hover:text-white"
                onClick={() => handleOptionClick(option.value)}              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="" />}
                {option.label}
              </div>
              </div>
            )
          ))}
        </div>
      </div>
    )}
  </div>
  );
};

const ViewCustomer = () => {
  const  [customers , setCustomers] = useState ([]);
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation()
  const {CustomerData}= location.state || {}
  const customerId = location.state?.customerId;
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [showAddress, setShowAddress] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [customerid , setcustomerid] = useState(null);
  const [newContactPerson, setNewContactPerson] = useState({
    name: '',    email: '',
    mobile_number: '',    designation: '',  });
  const [projects, setProjects] = useState([]);
  const [Quotations , setQuotations] = useState([]);
  const [SalesOrders , setSalesOrders] = useState([]);
  const [DeliveryChallans , setDeliveryChallans] = useState([]);
  const [showOtherDetails , setShowOtherDetails] = useState(false);
  const [showContactPersons , setShowContactPersons] = useState(false);
  const [ CustomerContactPersons , setCustomerContactPersons] = useState([]);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [showCustomerDetailsPopup, setCustomerDetailsPopup] = useState(false);
  const [showProjectDetailsPopup, setShowProjectDetailsPopup] = useState(false);
  const [showAddContactPersonPopup, setShowAddContactPersonPopup] = useState(false);
  const [ShowEditContactPersonPopup ,setShowEditContactPersonPopup] = useState(false);
  const [ShowDeleteContactPersonPopup , setShowDeleteContactPersonPopup] = useState(false);
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [isSaving, setIsSaving] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [isPanEditing, setIsPanEditing] = useState(false);
const [panValue, setPanValue] = useState();
const [isGSTEditing, setIsGSTEditing] = useState(false);
const [GSTValue, setGSTValue] = useState();
const [address, setAddress]=useState()
  const [city, setCity]=useState()
  const [isAddressEditing, SetisAddressEditing] = useState (false)
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [customerStatus, setCustomerStatus] = useState('Deactivate');
  const [customerApprovalStatus, setCustomerApprovalStatus] = useState(null); // Track the current customer status
  const [loading, setLoading] = useState(false); // Track the loading state for API calls
  const [attachmentPopUp, setAttachmentPopUp] = useState(false);  
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState('');
  const [fileViewMode, setFileViewMode] = useState('image');


    const fetchCustomers = async (Customer,attempt = 1) => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `customer/get-all-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                }
            });
            const data = await response.json();
            console.log(data)
    if (data.status === 200 || data.status === 201 || data.status === 204 ) {
      setCustomers(data.data);
      let FindCustomer;
      if(customerId){
        FindCustomer = data?.data.find(p => p?._id===customerId)
      }else{
      FindCustomer = data?.data.find(p => p?._id===Customer?._id)
      }
      handleCustomerClick(FindCustomer);
      setcustomerid(FindCustomer?._id);
    } else if (data.status === 404) {
      setCustomers(data.message);  
    }  else {
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
    }catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchCustomers(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchCustomers(attempt)); // Set retry function
      }
    }
    };

    useEffect(() => {
    fetchCustomers(CustomerData);
}, [CustomerData]);

/* useEffect(()=>{
  if(selectedCustomer){
  }else{
    setcustomerid(CustomerData?._id);
    setSelectedCustomer(CustomerData)
  }
},[CustomerData]) */

const fetchProjectsByCustomerId = async (customerId,attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const GetProjects = `${backendApiUrl}customer/get-projects-by-customer?customer_id=${customerId}`;
    const response = await fetch(GetProjects, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });
    const data = await response.json();
    console.log(data.data);
    if (data.status === 200 || data.status === 201 || data.status === 204 ) {
      setProjects(data.data || []);
    } else if (data.status === 404) {
      setProjects(data.message);  
    }  else if (data.status === 500) {
      setPopupMessage(data.message);
      setPopupType('500');
      setRetryFunction(() => fetchProjectsByCustomerId);
      setShowPopup(true);  
    } 
    else {
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProjectsByCustomerId(customerId,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProjectsByCustomerId(customerId,attempt)); // Set retry function
      }
    }
    };

    const fetchQuotationsByCustomerId = async (customerId ,attempt = 0) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotations-by-customer?customer_id =${customerId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
  
        const data = await response.json();
        console.log(data.data);
        if (data.status === 200 || data.status === 201 || data.status === 204 ) {
          setQuotations(data.data || []);
        } else if (data.status === 404) {
          setQuotations(data.message);  
        }  else if (data.status === 500) {
          setPopupMessage(data.message);
          setPopupType('500');
          setRetryFunction(() => fetchQuotationsByCustomerId);
          setShowPopup(true);  
        } 
        else {
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchQuotationsByCustomerId(customerId,attempt + 1), 1000); // Retry after 1 second
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchQuotationsByCustomerId(customerId,attempt)); // Set retry function
          }
        }
        };

        const fetchSalesOrdersByCustomerId = async (customerId ,attempt = 0) => {
          try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-salesorders-by-customer?customer_id =${customerId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-token': token,
              },
            });
      
            const data = await response.json();
            console.log(data.data);
            if (data.status === 200 || data.status === 201 || data.status === 204 ) {
              setSalesOrders(data.data || []);
            } else if (data.status === 404) {
              setSalesOrders(data.message);  
            }  else if (data.status === 500) {
              setPopupMessage(data.message);
              setPopupType('500');
              setRetryFunction(() => fetchSalesOrdersByCustomerId);
              setShowPopup(true);  
            } 
            else {
              setPopupMessage(data.message);
              setPopupType(`${data.status}`);
              setShowPopup(true);
            }
            } catch (error) {
              console.error('Failed to fetch:', error);
        
              if (attempt < RETRY_LIMIT) {
                setRetryCount(attempt + 1); // Increment retry count
                setTimeout(() => fetchSalesOrdersByCustomerId(customerId,attempt + 1), 1000); // Retry after 1 second
              } else {
                setIsLoading(false);
                setPopupMessage('Internal Server Error. Kindly Retry.');
                setPopupType('500');
                setShowPopup(true);
                setRetryFunction(() => () => fetchSalesOrdersByCustomerId(customerId,attempt)); // Set retry function
              }
            }
            };

            
        const fetchDeliveryChallansByCustomerId = async (customerId ,attempt = 0) => {
          try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/delivery-challan/get-delivery-challans-by-customer?customer_id =${customerId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-token': token,
              },
            });
      
            const data = await response.json();
            console.log(data.data);
            if (data.status === 200 || data.status === 201 || data.status === 204 ) {
              setDeliveryChallans(data.data || []);
            } else if (data.status === 404) {
              setDeliveryChallans(data.message);  
            }  else if (data.status === 500) {
              setPopupMessage(data.message);
              setPopupType('500');
              setRetryFunction(() => fetchDeliveryChallansByCustomerId);
              setShowPopup(true);  
            } 
            else {
              setPopupMessage(data.message);
              setPopupType(`${data.status}`);
              setShowPopup(true);
            }
            } catch (error) {
              console.error('Failed to fetch:', error);
        
              if (attempt < RETRY_LIMIT) {
                setRetryCount(attempt + 1); // Increment retry count
                setTimeout(() => fetchDeliveryChallansByCustomerId(customerId,attempt + 1), 1000); // Retry after 1 second
              } else {
                setIsLoading(false);
                setPopupMessage('Internal Server Error. Kindly Retry.');
                setPopupType('500');
                setShowPopup(true);
                setRetryFunction(() => () => fetchDeliveryChallansByCustomerId(customerId,attempt)); // Set retry function
              }
            }
            };

useEffect(() => {
  if (selectedCustomer) {
    fetchProjectsByCustomerId(selectedCustomer._id);
    fetchQuotationsByCustomerId(selectedCustomer._id);
    fetchSalesOrdersByCustomerId(selectedCustomer._id);
    fetchDeliveryChallansByCustomerId(selectedCustomer._id);
    fetchContactPersonsByCustomerId(selectedCustomer._id)
  }
}, [selectedCustomer]);

useEffect(() => {
  console.log(customerId);
  if (customerId) {
    fetchProjectsByCustomerId(customerId);
    fetchContactPersonsByCustomerId(customerId);
  }
  if(customers){
    setSelectedCustomer(customers.find(customer => customer._id === customerId));
  }

  }, [customerId]);

const fetchContactPersonsByCustomerId = async (customerId,attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const GetContactPerson = `${backendApiUrl}customer/get-customer-and-contacts?customer_id=${customerId}`;
    const response = await fetch(GetContactPerson, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });
    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204 ) {
      setCustomerContactPersons(data.data.contacts? data.data.contacts : []); // set to empty array if data.contacts is null or undefined
    } else if (data.status === 404) {
      setCustomerContactPersons([]); // set to empty array if response status is 404
    }   else if (data.status === 500) {
      setPopupMessage(data.message);
      setPopupType('500');
      setRetryFunction(() => fetchContactPersonsByCustomerId);
      setShowPopup(true);  
    }      else {
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchContactPersonsByCustomerId(customerId,attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchContactPersonsByCustomerId(customerId,attempt)); // Set retry function
    }
  }
};

const handleCustomerInputfieldChange = (e) => {
  const { id, value } = e.target;
  setSelectedCustomer((prevSelectedCustomer) => ({
     ...prevSelectedCustomer,
     [id]: value
  }));
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setSelectedProject((prevProject) => ({ ...prevProject, [name]: value }));
};

const handleProjectDetailsSave = async (attempt = 1) => {
  setIsSaving(true);
  const token = sessionStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FORE_APILINK}/projects/update-project?projectId=${selectedProject._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify(selectedProject),
      }
    );
    const data = await response.json();
    if (response.status === 200 || data.status === 201 || data.status === 204) {
      updateProjectInList(selectedProject); // Update the project data in the list
      setShowProjectDetailsPopup(false);
      setIsSaving(false);
      setShowPopup(true);
      setPopupType("200or201");
      setPopupMessage("Project Details Updated Successfully.");
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    } else if (data.status === 500) {
      setShowProjectDetailsPopup(false);
      setIsSaving(false);
      setShowPopup(true);
      setRetryFunction(() => handleProjectDetailsSave);
      setShowProjectDetailsPopup(false);
      setPopupType("500");
      setPopupMessage(data.message);
      // Do not close the vendor details popup, allow the user to retry
    } else {
      setIsSaving(false);
      setPopupMessage(data.message);
      setShowProjectDetailsPopup(false);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error("Failed to fetch:", error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleProjectDetailsSave(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => handleProjectDetailsSave(attempt)); // Set retry function
    }
  }
};

const updateProjectInList = (selectedProject) => {
  const updatedProjects = projects.map((project) => {
    if (project._id === selectedProject._id) {
      return { ...selectedProject };
    }
    return project;
  });
  setProjects(updatedProjects);
};

const handleUpdateCustomer = async (attempt = 1) => {
  const token = sessionStorage.getItem('token');
  const url = process.env.REACT_APP_FORE_BRICKBUCKET + "customer/update-customer";
  // Initialize error messages array
  const errorMessages = [];
  if (!selectedCustomer.customer_name) {
    errorMessages.push('Name is required.');
  }
    // Validate mobile number required
    if (!selectedCustomer.mobile_number) {
      errorMessages.push('Mobile number is required.');
    } else if (selectedCustomer.mobile_number.length !== 10) {
      // Validate mobile number length
      errorMessages.push('Mobile number must be exactly 10 digits.');
    }

  // Validate email if provided
  if (selectedCustomer.email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(selectedCustomer.email)) {
      errorMessages.push('Invalid email address.');
    }
  }

  // If there are any errors, display them and stop the execution
  if (errorMessages.length > 0) {
    setErrorMessages(errorMessages);
    setIsSaving(false);
    return;
  }
  setIsSaving(true);
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(selectedCustomer)
    });

    const responseData = await response.json();
    console.log('Response data:', responseData); // Log the response

    if (responseData.status === 200 || responseData.status === 201 || responseData.status === 204) {
      handleSetUpdatingCustomersData(selectedCustomer);
      // Close the current popup and loader
      setCustomerDetailsPopup(false);
      setIsSaving(false);
      setIsPanEditing(false);
      setIsGSTEditing(false);
      SetisAddressEditing(false);
      // Display a new popup with a 5-second timer and response message
      setShowPopup(true);
      setPopupType('200or201');
      setPopupMessage(responseData.message);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    } else if (responseData.status === 500) {
      setCustomerDetailsPopup(false);
      setRetryFunction(() => handleUpdateCustomer);
      setIsSaving(false);
      setShowPopup(true);
      setPopupType('500');
      setPopupMessage(responseData.message);
    } else {
      console.error('Unknown error occurred:', responseData);
      setShowPopup(true);
      setPopupType('400');
      setPopupMessage('Unknown error occurred');
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleUpdateCustomer(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleUpdateCustomer(attempt)); // Set retry function
    }
  } finally {
    setErrorMessages([]);
  }
};


const handleSetUpdatingCustomersData = async (selectedCustomer) => {
  const FindingCustomerIndex = customers.findIndex(p => p._id === selectedCustomer._id);
  if (FindingCustomerIndex !== -1) {
    // Create a new array with the updated customer data to avoid mutating the state directly
    const updatedCustomers = customers.map((customer, index) =>
      index === FindingCustomerIndex
        ? { ...customer, ...selectedCustomer }
        : customer
    );
    // Update the state with the new array
    setCustomers(updatedCustomers);
  }
};

  const handleCustomerClick = (customer) => {
    setcustomerid(customer?._id);
    setSelectedCustomer(customer);
    setCustomerApprovalStatus(customer?.customer_status);
    console.log(customer?.customer_status)
  };

  const handleToggleAddress = () => {
    setShowAddress(!showAddress);
    setShowOtherDetails(false);
    setShowContactPersons(false);
  };

  const handleToggleOtherDetails = () => {
    setShowOtherDetails(!showOtherDetails);
    setShowContactPersons(false);
    setShowAddress(false);
  };

  const handleToggleContactPersons = () => {
    setShowContactPersons(!showContactPersons);
    setShowAddress(false);
    setShowOtherDetails(false);
  };


  const handleProjectNameClick = (project) => {
    setSelectedProject(project);
    setShowProjectDetailsPopup(true);
  };

const handleEditClick = () => {
  setCustomerDetailsPopup(true);
};

const handleDeactivateClick = async (attempt = 1) => {
  console.log(customerid);
  setIsLoading(true);
  try {
    const token = sessionStorage.getItem('token');
    const ActivateURL = `${backendApiUrl}customer/deactivate-customer?customerId=${customerid}`;
    const response = await fetch(ActivateURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
    });
    const data = await response.json();
    console.log(data);
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      setSelectedCustomer((prevCustomer) => ({ ...prevCustomer, is_active: false }));
      setCustomerStatus('Activate');
      setShowPopup(true);
      setIsLoading(false);
      setPopupType('200or201');
      setPopupMessage(data.message);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      // Update the vendor status in the local state
      setCustomers(customers.map((customer) => {
        if (customer._id === customerid) {
          customer.is_active = false;
        }
        return customer;
      }));
    } else {
      setPopupMessage(data.message);
      setIsLoading(false);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleDeactivateClick(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleDeactivateClick(attempt)); // Set retry function
    }
  }
};

const handleActivateClick = async (attempt = 1) => {
  setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const ActivateURL = `${backendApiUrl}customer/activate-customer?customerId=${customerid}`;
      const response = await fetch(ActivateURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      console.log(data);
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setSelectedCustomer((prevCustomer) => ({ ...prevCustomer, is_active: true }));
        setCustomerStatus('Deactivate');
        setShowPopup(true);
        setIsLoading(false);
        setPopupType('200or201');
        setPopupMessage(data.message);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        // Update the vendor status in the local state
        setCustomers(customers.map((customer) => {
          if (customer._id === customerid) {
            customer.is_active = true;
          }
          return customer;
        }));
      } else {
        setPopupMessage(data.message);
        setIsLoading(false);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleActivateClick(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleActivateClick(attempt)); // Set retry function
      }
    }
  };

const handlePlusIconClick = () => {
  setShowAddContactPersonPopup(true);
};

const handleEditContactPerson = (contactPerson) => {
  setSelectedContactPerson(contactPerson);
  setShowEditContactPersonPopup(true);
};

const handleEditPanClick = () => {
  setIsPanEditing(true);
  setIsGSTEditing(false)
  setPanValue(selectedCustomer.pan)
};
const handleEditGSTClick = () => {
  setIsGSTEditing(true);
  setIsPanEditing(false);
  setGSTValue(selectedCustomer.gstin)
};

const handleEditAddress = () =>{
  SetisAddressEditing(true)
  setIsPanEditing(false);
  setIsGSTEditing(false)
  setAddress(selectedCustomer.address)
  setCity(selectedCustomer.city)
}
 
const handleCancelClick = () => {
  setIsPanEditing(false);
  setIsGSTEditing(false);
  SetisAddressEditing (false)
};

const handleCreateContactPersonInputfieldChange = (e) => {
  const { id, value } = e.target;
  setNewContactPerson((prevNewContactPerson) => ({ ...prevNewContactPerson, [id]: value }));
};

const handleUpdateContactPersonInputfieldChange = (e) => {
  const { id, value } = e.target;
  setSelectedContactPerson((prevSelectedContactPerson) => ({ ...prevSelectedContactPerson, [id]: value }));
};

const handleUpdateContactPerson = async (attempt = 1) => {
  if (selectedContactPerson) {
    // Update contact person
    const updatedContactPerson = {
      _id: selectedContactPerson._id,
      customer_id: selectedContactPerson.customer_id,
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      mobile_number: document.getElementById('mobile_number').value,
      designation: document.getElementById('designation').value,
    };
    const errorMessages = [];
  if (!updatedContactPerson.name) {
    errorMessages.push('Name is required.');
  }
    // Validate mobile number required
    if (!updatedContactPerson.mobile_number) {
      errorMessages.push('Mobile number is required.');
    } else if (updatedContactPerson.mobile_number.length !== 10) {
      // Validate mobile number length
      errorMessages.push('Mobile number must be exactly 10 digits.');
    }

   // Validate email if provided
   if (updatedContactPerson.email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(updatedContactPerson.email)) {
      errorMessages.push('Invalid email address.');
    }
  }

    if (errorMessages.length > 0) {
      setErrorMessages(errorMessages);
      return;
    }

    setIsSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const url = process.env.REACT_APP_FORE_BRICKBUCKET + "customer/update-customer-contact";
      const response = await fetch(url, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 
                  'x-token': token 
        },
        body: JSON.stringify(updatedContactPerson),
      });

      const data = await response.json();

      if (data.status === 200 || data.status === 201 || data.status === 204 ) {
        setIsSaving(false);
        setPopupMessage(data.message);
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        // Update successful, close the popup and update the CustomerContactPersons state
        const index = CustomerContactPersons.findIndex((contactPerson) => contactPerson._id === selectedContactPerson._id);
        if (index !== -1) {
          const updatedCustomerContactPersons = [...CustomerContactPersons];
          updatedCustomerContactPersons[index] = updatedContactPerson;
          setCustomerContactPersons(updatedCustomerContactPersons);
          setShowEditContactPersonPopup(false);
        }
      } else {
        setIsSaving(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    }catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleUpdateContactPerson(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleUpdateContactPerson(attempt)); // Set retry function
      }
    }
    finally{
      setErrorMessages([]);
    }
  }  
};

const handleCreateContactPerson = async (attempt = 1) => {
  // Save new contact person
  const newContactPerson = {
    customer_id: customerid, // Assuming you have a customerId state or prop
    name: document.getElementById('name').value,
    email: document.getElementById('email').value,
    mobile_number: document.getElementById('mobile_number').value,
    designation: document.getElementById('designation').value,
  };
  const errorMessages = [];
  if (!newContactPerson.name) {
    errorMessages.push('Name is required.');
  }
    // Validate mobile number required
    if (!newContactPerson.mobile_number) {
      errorMessages.push('Mobile number is required.');
    } else if (newContactPerson.mobile_number.length !== 10) {
      // Validate mobile number length
      errorMessages.push('Mobile number must be exactly 10 digits.');
    }
   // Validate email if provided
   if (newContactPerson.email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(newContactPerson.email)) {
      errorMessages.push('Invalid email address.');
    }
  }
  if (errorMessages.length > 0) {
    setErrorMessages(errorMessages);
    return;
  }

  setIsSaving(true);
  try {
    const token = sessionStorage.getItem('token');
    const url = process.env.REACT_APP_FORE_BRICKBUCKET + "customer/create-customer-contact";
    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 
                'x-token': token 
      },
      body: JSON.stringify(newContactPerson),
    });

    const data = await response.json();
    if (response.ok) {
      
      console.log(data);
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      // Save successful, close the popup and update the CustomerContactPersons state
      setShowAddContactPersonPopup(false);
      const newCustomerContactPersons = [...CustomerContactPersons, data.data];
      setCustomerContactPersons(newCustomerContactPersons);
    } else {
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleCreateContactPerson(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleCreateContactPerson(attempt)); // Set retry function
    }
  }
  finally{
    setErrorMessages([]);
  }
};


const handleDeleteContactPerson = (contactPerson) => {
  setSelectedContactPerson(contactPerson);
  setShowDeleteContactPersonPopup(true);
}

const handleConfirmDelete = async (attempt = 1) => {
  const token = sessionStorage.getItem('token');
  const customerContactId = selectedContactPerson._id;
  const RETRY_LIMIT = 3; // Define your retry limit
  setIsSaving(true);

  try {
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `customer/delete-customer-contact?customer_contacts_id=${customerContactId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        "x-token": token
      }
    });

    const data = await response.json();

    if (data.status === 200 || data.status === 201 || data.status === 204) {
      // Update successful, close the popup and update the CustomerContactPersons state
      const index = CustomerContactPersons.findIndex((contactPerson) => contactPerson._id === customerContactId);
      if (index !== -1) {
        const updatedCustomerContactPersons = [...CustomerContactPersons];
        updatedCustomerContactPersons.splice(index, 1); // Remove the deleted contact person
        setCustomerContactPersons(updatedCustomerContactPersons);
      }

      setPopupMessage(data.message);
      setPopupType('204');
    } else {
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);

      if (data.status === 500) {
        setRetryFunction(() => handleConfirmDelete); // Set retry function
      }
    }

    setIsSaving(false);
    setIsLoading(false);
    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 3000);

    setShowDeleteContactPersonPopup(false);
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleConfirmDelete(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsSaving(false);
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => handleConfirmDelete); // Set retry function
      setShowDeleteContactPersonPopup(false);
    }
  }
}



const handleClosePopup = () => {
  setIsSaving(false);
  setCustomerDetailsPopup(false);
  setShowAddContactPersonPopup(false);
  setShowProjectDetailsPopup(false);
  setShowEditContactPersonPopup(false);
  setShowDeleteContactPersonPopup(false);
  setErrorMessages([]);
};

const handleCreateCustomer = () => {
  navigate('/bb/app/sales/newcustomer')
}

const handleClose = () => {
  navigate('/bb/app/sales/customerslist');
}


const handleCloseError = () => {
  setErrorMessages([]);
};

const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

const handleEdit = () => {
  navigate('/bb/app/sales/editcustomer', {state:{CustomerData:selectedCustomer}});
}



const handleApprove = async () => {
  setLoading(true);
  const token = sessionStorage.getItem('token');

  try {
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET +'customer/approve-customer', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token 
      },
      body: JSON.stringify({ _id: customerid }),
    });

    const data = await response.json();
    console.log(data);
  
    if (data.success === true)  {
      console.log(data.customer_status)
      setCustomerApprovalStatus(3); // Set the customer status to approved (3)
    }
  } catch (error) {
    console.error('Error approving customer:', error);
  } finally {
    setLoading(false);
  }
};

const handleDisapprove = async () => {
  const token = sessionStorage.getItem('token');

  setLoading(true);
  try {
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET +'customer/disapprove-customer', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token 
      },
      body: JSON.stringify({ _id: customerid }),
    });

    const data = await response.json();
    console.log(data);
    if (data.success === true) {
      console.log(data.customer_status)
      setCustomerApprovalStatus(2); // Set the customer status to disapproved (2)
    }
  } catch (error) {
    console.error('Error disapproving customer:', error);
  } finally {
    setLoading(false);
  }
};

const handleAttachmentClick = () => {
  setAttachmentPopUp(true);
  setIsImageModalOpen(false); // Ensure the image modal is closed

}

const handleclose = () => {
  setAttachmentPopUp(false);
  //setSelectedAttachment(false);
}

const handleViewAttachment = (attachment) => {
  setSelectedAttachment(attachment);
  setIsImageModalOpen(true);

  // Determine file type and set appropriate view mode
  const fileExtension = attachment.split('.').pop().toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
    setFileViewMode('image');
  } else if (['pdf'].includes(fileExtension)) {
    setFileViewMode('pdf');
  } else if (['doc', 'docx', 'xls', 'xlsx', 'csv'].includes(fileExtension)) {
    setFileViewMode('office');
  } else {
    setFileViewMode('unsupported');
  }
};



const handleRemoveFile = () => {
  setSelectedFile(null);
  document.getElementById('fileInput').value = null; // Reset the file input value
};


const handleFileUpload = async (event) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    setSelectedFile(file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('customerId', customerid);

    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/add-customer-attachments`, {
        method: 'PUT',
        body: formData,
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      });

      const data = await response.json();

      if (response.ok) {
        setPopupMessage('File uploaded successfully');
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        // Update attachments state with the new file
        setAttachments(prevAttachments => [...prevAttachments, file.name]);
        setSelectedFile(null);
      } else {
        setPopupMessage(data.message || 'Failed to upload file');
        setPopupType(`${response.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setPopupMessage('Error uploading file');
      setPopupType('500');
      setShowPopup(true);
    }
  }
};



const handleButtonClick = () => {
  document.getElementById('fileInput').click();
};

useEffect(() => {
  if (customerid) {
    getCustomerAttachments();
  }
}, [customerid]);




const getCustomerAttachments = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/get-customer-attachments?customerId=${customerid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': sessionStorage.getItem('token')
      }
    });

    const data = await response.json();

    if (data.status === 200) {
      // Handle successful retrieval of attachments
      console.log('Attachments:', data.data);
      setAttachments(data.data);

      // You can set these attachments to a state variable if needed
      // setAttachments(data.data);
    } else {
      // Handle error
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Error fetching attachments:', error);
    setPopupMessage('Error fetching attachments');
    setPopupType('500');
    setShowPopup(true);
  }
};

const handleNavigateProjectDetails = (project)=>{
  // console.log(project)
  navigate('/bb/app/projects/projectdetails', {state:{projectData: project}} );
}

const handleNavigateToQuoteDetails = (quotation) => {
  navigate("/bb/app/sales/quotedetails", {state:{QuoteData:quotation}});
  console.log(quotation);
}

const handleNavigateSODetails = (so) => {
  navigate("/bb/app/sales/salesorderdetails", {state:{SalesOrderData:so}});
  
}

const handleNavigateDCDetails = (dc) => {
  navigate("/bb/app/deliverychallans/deliverychallandetails", {state:{DeliveryChallanData:dc}});
  
}

  return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
      {/* List of Customers Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Customers
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button onClick={handleCreateCustomer}
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md" 
  title="" data-tooltip="Create Customer" style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>
  <div className=" flex justify-center  overflow-y-hidden  flex-col ">
   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
   {customers?.map((customer) => (
        <div
          key={customer?._id}
          onClick={() => handleCustomerClick(customer)}
          className={`border-b text-sm flex items-center px-4 py-2 ${customerid === customer?._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        >
          <div className='flex flex-row'>
            <div>
              <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
            </div>
            <div className='flex flex-col'>
              <p className='text-md font-semibold' >{customer?.customer_name}</p>
              <p className="text-gray-600 text-sm">{customer?.company_name}</p>
            </div>
          </div>
        </div>
      ))}
  </div>
</div>
</div>
{/* List of Customers Div end */}
<div className="border-l  w-full flex flex-col ">
{selectedCustomer ? (
  <>
<div className="border-b h-24"> {/* or h-12, depending on your desired height */}
  <div className='p-4 flex justify-between items-center '>
    <span className="text-md font-semibold">{selectedCustomer.customer_name}</span>
    <div className="flex items-center px-2">
      <button className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md mr-2" onClick={handleEdit}>
        Edit
      </button>
      <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2" onClick={handleAttachmentClick} >
        <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer " />
        {attachments.length > 0 && (
    <span className="text-xs font-semibold">{attachments.length}</span>
  )}
      </button>
      {/* <button className="bg-gray-100 text-sm px-2 py-1 shadow-sm border rounded-md mr-2">
        More
      </button> */}
      <button className=" text-white px-1 py-1 rounded-md "
        title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>   
        <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer " />
      </button>
    </div>
  </div>
</div>
  <div className=" flex h-5/6 w-full  overflow-y-auto  flex-row">
  <div className="bg-[#fbfbfb] w-[32%] border-r  p-4">
    <h2 className="text-sm pb-2 border-b">
    {selectedCustomer.company_name}
    </h2>
    <div className='pt-4 pl-4 pr-4 flex flex-row items-start'>
  <div className='flex-shrink-0'>
    <img className="rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
  </div>
  <div className='text-sm ml-3 flex flex-col flex-grow'>
    <div className='font-semibold mb-0.5 break-words word-break'>{selectedCustomer.customer_name}</div>
    <div className='break-words word-break'>{selectedCustomer.email}</div>
    <div className='break-words'>
      <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
      {selectedCustomer.mobile_number}
    </div>
    <div className='text-xs flex flex-row'>
      <button onClick={handleEditClick} className='text-[#408dfb] hover:text-blue-600'>Edit</button>
      <p className='text-gray-300 ml-2 mr-2 '>|</p>
      <button
        onClick={selectedCustomer.is_active ? handleDeactivateClick : handleActivateClick}
        className='text-[#408dfb] hover:text-blue-600'
      >
        {selectedCustomer.is_active ? 'Deactivate' : 'Activate'}
      </button>
    </div>
  </div>
</div>          


<div onClick={handleToggleAddress}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className=' text-sm cursor-pointer' onClick={handleToggleAddress}>ADDRESS</span>
  <button className=' text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleAddress}>
    {showAddress? <FontAwesomeIcon icon={faChevronUp} className='text-xs text-blue-800'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs text-blue-800'/>}
  </button>
</div>

<div className={`${showAddress ? '' : 'hidden'}`}>
  <div className='text-sm pt-2 pl-4 pr-4'>
    <div className='flex justify-between'><h1>Billing Address</h1>
    {!isAddressEditing&&
    <FontAwesomeIcon icon={faPen} className='text-xs'
    onClick={handleEditAddress}/> 
    }
    </div>

    <div className='flex flex-col'>
    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
<div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end'>
    {isAddressEditing ? (
      <div className='flex flex-col space-y-2'>
      <input 
        type="text" 
        value={address} 
        onChange={(e) => {setAddress(e.target.value); selectedCustomer.address = e.target.value}} 
        className="border border-gray-300 w-30 rounded px-2 py-1"
      />
      <input 
        type="text" 
        value={city} 
        onChange={(e) => {setCity(e.target.value); selectedCustomer.city = e.target.value}} 
        className="border border-gray-300 w-30 rounded px-2 py-1"
      />
       </div>
    ) : (
 <>
 <div className='flex flex-col space-y-2'>

  <p>{selectedCustomer.address}</p>

  <p>{selectedCustomer.city}</p>
  </div>
  </>
)}
  <div className='flex items-center'>
  {isSaving && (
          <Spinner/>)}
    {isAddressEditing &&(
      <>
        <FontAwesomeIcon 
          icon={faSave} 
          className='mr-2 text-xs ml-2 cursor-pointer' 
          onClick={handleUpdateCustomer } 
        />
        <FontAwesomeIcon 
          icon={faTimes} 
          className='mr-2 text-xs ml-2 cursor-pointer' 
          onClick={handleCancelClick} 
        />
      </>
    )}
  </div>
  </div>
</div>
    </div>
  </div>
</div>

<div onClick={handleToggleOtherDetails}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className='text-sm cursor-pointer' onClick={handleToggleOtherDetails}>TAX DETAILS</span>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleOtherDetails}>
    {showOtherDetails? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
</div>

<div className={`grid grid-cols-2 gap-2 mt-2 mb-2 ${showOtherDetails ? '' : 'hidden'}`}>
  <div className="text-[#212529] text-xs pt-2 pl-2 pr-2">
    <h1>ASSOCIATION</h1>
  </div>
  <div className="flex justify-start text-xs pt-2 pb-2 pl-1 pr-4">
  <p>{selectedCustomer.association ? selectedCustomer.association.toUpperCase() : ''}</p>
  </div>

  <div className="text-[#212529] text-xs pt-2 pl-2 pr-2">
    <h1>GSTIN</h1>
  </div>
  <div className="flex flex-row hover:bg-[#d7d7ec] hover:rounded-md">
    <div className="flex justify-start text-xs pt-2 pb-2 pl-1 pr-1">
      {isGSTEditing ? (
        <input
          type="text"
          value={GSTValue}
          onChange={(e) => {
            setGSTValue(e.target.value);
            selectedCustomer.gstin = e.target.value;
          }}
          className="border border-gray-300 w-full rounded px-2 py-1"
        />
      ) : (
        <p>{selectedCustomer.gstin}</p>
      )}
    </div>
    <div className="flex items-center">
      {isSaving && <Spinner />}
      {isGSTEditing ? (
        <>
          <FontAwesomeIcon
            icon={faSave}
            className="mr-2 text-xs ml-2 cursor-pointer"
            onClick={handleUpdateCustomer}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className="mr-2 text-xs ml-2 cursor-pointer"
            onClick={handleCancelClick}
          />
        </>
      ) : (
        <FontAwesomeIcon
          icon={faPen}
          className="mr-2 text-xs ml-2 cursor-pointer"
          onClick={handleEditGSTClick}
        />
      )}
    </div>
  </div>

  <div className="text-[#212529] text-xs pt-2 pl-2 pr-2">
    <h1>PAN</h1>
  </div>
  <div className="flex flex-row hover:bg-[#d7d7ec] hover:rounded-md">
    <div className="flex justify-start text-xs pt-2 pb-2 pl-1 pr-1">
      {isPanEditing ? (
        <input
          type="text"
          value={panValue}
          onChange={(e) => {
            setPanValue(e.target.value);
            selectedCustomer.pan = e.target.value;
          }}
          className="border border-gray-300 w-full rounded px-2 py-1"
        />
      ) : (
        <p>{selectedCustomer.pan}</p>
      )}
    </div>
    <div className="flex items-center">
      {isSaving && <Spinner />}
      {isPanEditing ? (
        <>
          <FontAwesomeIcon
            icon={faSave}
            className="mr-2 text-xs ml-2 cursor-pointer"
            onClick={handleUpdateCustomer}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className="mr-2 text-xs ml-2 cursor-pointer"
            onClick={handleCancelClick}
          />
        </>
      ) : (
        <FontAwesomeIcon
          icon={faPen}
          className="mr-2 text-xs ml-2 cursor-pointer"
          onClick={handleEditPanClick}
        />
      )}
    </div>
  </div>
</div>
<div onClick={handleToggleContactPersons}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center mb-8'>
  <div>
  <span className='text-sm cursor-pointer' onClick={handleToggleContactPersons}>CONTACT PERSONS</span>
  </div>
  <div>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleContactPersons}>
    <FontAwesomeIcon icon={faPlusCircle} onClick={handlePlusIconClick} className='text-blue-500 text-md'/>
  </button>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleContactPersons}>
    {showContactPersons? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
  </div>
</div>
<div className={`${showContactPersons? '' : 'hidden'}`}>
{Array.isArray(CustomerContactPersons) && CustomerContactPersons.length > 0 ? (
      CustomerContactPersons.map((contactPerson) => (
        <div key={contactPerson._id} className='pt-4 pl-4 pr-4 flex flex-row'>
          <div className='w-[90%] flex flex-row'>
            <div className='w-2/5'>
              <img className="rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
            </div>
            <div className='w-4/5 text-sm ml-3 flex flex-col'>
              <div className='font-semibold mb-0.5 break-words word-break'>{contactPerson.name}</div>
              <div className='break-words word-break'>{contactPerson.email}</div>
              <div className='flex items-center'>
                <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
                <span>{contactPerson.mobile_number}</span>
              </div>
              <div className='mb-0.5 break-words word-break'>{contactPerson.designation}</div>
            </div>
          </div>
          <div className='w-[5%]'></div>
          <div className='w-[10%] flex justify-end'>
            <FontAwesomeIcon icon={faPen} onClick={() => handleEditContactPerson(contactPerson)} className='cursor-pointer hover:text-gray-500 text-gray-300 text-xs'/>
            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteContactPerson(contactPerson)} className='cursor-pointer hover:text-gray-500 text-gray-300 text-xs ml-1'/>
          </div>
        </div>
      ))
    ) : (
      <div className='flex justify-center text-sm'>No contact persons found</div>
    )}
</div>

<div className="bg-[#f2fff0] flex flex-col items-center border border-[#c6efce] mt-10 p-4 rounded mx-auto w-[220px] h-[140px]">
      <div className="text-black mb-4 text-sm flex justify-between space-x-4">
        <FontAwesomeIcon icon={faVcard} className="cursor-pointer text-[#7CB541] text-lg relative top-1" />
        <span>Customer KYC has to be verified before approval in customer</span>
      </div>
      <div className="flex justify-center items-center">
        {customerApprovalStatus && (
          <>{customerApprovalStatus === 3 ? (
            // If approved, show disapprove button
            <button
              className="bg-[#F5F5F5] border border-gray-400 text-black py-1 px-2 rounded text-sm"
              onClick={handleDisapprove}
              disabled={loading}
            >
              Disapprove
            </button>
          ) : (
            // If disapproved or null, show approve button
            <button
              className="bg-[#F5F5F5] border border-gray-400 text-black py-1 px-2 rounded text-sm"
              onClick={handleApprove}
              disabled={loading}
            >
              Approve
            </button>
          )}</>
        )}
        
      </div>
    </div>  </div>

  <div className="w-[68%]  pl-2">
  <div className="w-full  ">
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-col justify-center items-center">
              {Array.isArray(projects) && projects.length > 0? (
                <div className="overflow-x-auto w-full container mx-auto px-4">

                  <div className="p-2 flex justify-between items-center">
                    <h1 className="text-md font-semibold">Projects</h1>
                  </div>

  <table class="border-t border-b w-full">
    <thead>
      <tr className="text-[#212529] bg-[#fafafc]">                     
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Project Name
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Blocks
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Site Contact Name 
        </th>
        <th className="text-right font-semibold text-xs py-2 px-4 border-b cursor-pointer">
          Site Contact No 
        </th>
      </tr>
    </thead>
    <tbody>
      {projects.map((project, index) => (
        <tr key={index} className="text-xs  border-b">
          <td /* onClick={() => handleProjectNameClick(project)} */ onClick={() => handleNavigateProjectDetails(project)}
          className="py-2 px-4 text-left text-blue-600 cursor-pointer">{project.projectName}</td>
          <td className="py-2 text-center px-4">{project.blockCount? project.blockCount : "---"}</td>
          <td className="py-2 text-center px-4">{project.siteContactName? project.siteContactName : '---'}</td>
          <td className="py-2 text-right px-4">{project.siteContactNo? project.siteContactNo : '---'}</td>
        </tr>
      ))}
    </tbody>
  </table>

                </div>
                ) : (
                  <div className="py-2 text-center">
                    {projects || 'No project found for this customer'}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center items-center">
              {Array.isArray(Quotations) && Quotations.length > 0? (
                <div className="overflow-x-auto w-full container mx-auto px-4">

                  <div className="p-2 flex justify-between items-center">
                    <h1 className="text-md font-semibold">Quotations</h1>
                  </div>

  <table class="border-t border-b w-full">
    <thead>
      <tr className="text-[#212529] bg-[#fafafc]">                     
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Quote Number
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Quotation Type
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Status
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">
          Total
        </th>
      </tr>
    </thead>
    <tbody>
      {Quotations.map((Quotation, index) => (
        <tr key={index} className="text-xs  border-b">
          <td  onClick={() => handleNavigateToQuoteDetails(Quotation)}
          className="py-2 px-4 text-left text-blue-600 cursor-pointer">{Quotation.quotation_number}</td>
          <td className="py-2 text-center px-4">{Quotation.quotation_type}</td>
          <td className="py-2 text-center px-4">{Quotation.status}</td>
          <td className="py-2 text-right px-4">Rs.{Quotation.total}</td>
        </tr>
      ))}
    </tbody>
  </table>

                </div>
                ) : (
                  <div className="py-2 text-center">
                    {Quotations || 'No quotations found for this customer'}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center items-center">
              {Array.isArray(SalesOrders) && SalesOrders.length > 0? (
                <div className="overflow-x-auto w-full container mx-auto px-4">

                  <div className="p-2 flex justify-between items-center">
                    <h1 className="text-md font-semibold">Sales Orders</h1>
                  </div>

  <table class="border-t border-b w-full">
    <thead>
      <tr className="text-[#212529] bg-[#fafafc]">                     
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Sales Order Number
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Location
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Status 
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer">
         Total 
        </th>
      </tr>
    </thead>
    <tbody>
      {SalesOrders.map((so, index) => (
        <tr key={index} className="text-xs  border-b">
          <td /* onClick={() => handleProjectNameClick(project)} */ onClick={() => handleNavigateSODetails(so)}
          className="py-2 px-4 text-left text-blue-600 cursor-pointer">{so.salesorder_number}</td>
          <td className="py-2 text-center px-4">{so.location}</td>
          <td className="py-2 text-center px-4">{so.status}</td>
          <td className="py-2 text-right px-4">Rs.{so.total}</td>
        </tr>
      ))}
    </tbody>
  </table>

                </div>
                ) : (
                  <div className="py-2 text-center">
                    {SalesOrders || 'No Sales Orders found for this customer'}
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center items-center">
              {Array.isArray(DeliveryChallans) && DeliveryChallans.length > 0? (
                <div className="overflow-x-auto w-full container mx-auto px-4">

                  <div className="p-2 flex justify-between items-center">
                    <h1 className="text-md font-semibold">Delivery Challans</h1>
                  </div>

  <table class="border-t border-b w-full">
    <thead>
      <tr className="text-[#212529] bg-[#fafafc]">                     
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          DC no
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          Status
        </th>
        <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
          SO Number
        </th>
        <th className="text-right font-semibold text-xs py-2 px-4 border-b cursor-pointer">
          Item Category 
        </th>
      </tr>
    </thead>
    <tbody>
      {DeliveryChallans.map((dc, index) => (
        <tr key={index} className="text-xs  border-b">
          <td /* onClick={() => handleProjectNameClick(project)} */ onClick={() => handleNavigateDCDetails(dc)}
          className="py-2 px-4 text-left text-blue-600 cursor-pointer">{dc.challan_number}</td>
          <td className="py-2 text-center px-4">{dc.status}</td>
          <td className="py-2 text-center px-4">{dc.sales_order_id.salesorder_number}</td>
          <td className="py-2 text-right px-4">{dc.product_category_id.product_category_name}</td>
        </tr>
      ))}
    </tbody>
  </table>

                </div>
                ) : (
                  <div className="py-2 text-center">
                    {DeliveryChallans || 'No Delivery Challans found for this customer'}
                  </div>
                )}
              </div>
            </div>
          </div>
  </div>
</div> 
</>
) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">Select a customer to view details</p>
          </div>
        )}
</div>

                   
 {/* Popup starts */}

 {showCustomerDetailsPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '60vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
      <h2 className="text-md">Edit Customer Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-1 '>
    {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )}
    <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Name
    </label>
    <input
      type="name"
      id="customer_name"
      value={selectedCustomer.customer_name }
      onChange={handleCustomerInputfieldChange}
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Enter Full Name"/>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Email Address
    </label>
    <input
      type="email"
      value={selectedCustomer.email}
      onChange={handleCustomerInputfieldChange}
      id="email"
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Email Address"
    />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Contact Info 
    </label>
    <input
      type="text"
      id="mobile_number"
      value={selectedCustomer.mobile_number}
      onChange={handleCustomerInputfieldChange}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Mobile"
       pattern="\d{10}" 
            maxLength="10"
    />
  </div>    
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button onClick={handleUpdateCustomer}
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
          <Spinner/>
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{showProjectDetailsPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '70vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full  px-6'>
      <h2 className="text-md">Edit Project Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-4 '>
    <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Project Name
    </label>
    <input
      type="name"
      value={selectedProject.projectName}
      onChange={handleInputChange}
      name="projectName"
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Enter Full Name"
    />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Builder Name
    </label>
    <input
      type="text"
      value={selectedProject.builderName}
      onChange={handleInputChange}
      name="builderName"
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Builder Name"
    />
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Builder Contact Number
    </label>
    <input
      type="text"
      value={selectedProject.builderContactNo}
      onChange={handleInputChange}
      name="builderContactNo"
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Work Phone"
      pattern="\d{10}" 
            maxLength="10"
    />
  </div>    

  <div className="w-full flex flex-row items-center mb-4 space-x-7 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Site Contact Name
    </label>
    <input
      type="text"
      value={selectedProject.siteContactName}
      onChange={handleInputChange}
      name="siteContactName"
      className="w-[40%] py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Site Address"/>
    <input
      type="text"
      value={selectedProject.siteContactNo}
      onChange={handleInputChange}
      name="siteContactNo"
      pattern="\d{10}" 
            maxLength="10"
      className="w-[40%] py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Site Contact"/>
  </div>   
</div>

<div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-3 mb-4">
        <button onClick={handleProjectDetailsSave}
        className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span>
         {isSaving && (<Spinner/>)}
         </button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{showAddContactPersonPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '70vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md">
    Add Contact Person
    </h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px '>
    {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )}
  <div className="w-full flex flex-row items-center mb-4 space-x-6 mt-5 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Name
    </label>
    <div className='w-5/6 flex justify-between'>
      <input
        type="text"
        id="name"
        value={newContactPerson.name}
        placeholder="Name"
        onChange={handleCreateContactPersonInputfieldChange }
        className="w-full py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"/>
    </div>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Email Address
    </label>
    <input
      type="email"
      id="email"
      value={newContactPerson.email}
      onChange={handleCreateContactPersonInputfieldChange }
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Email Address"    />
  </div> 
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Contact Info 
    </label>
    <input
      type="text"
      id="mobile_number"
      onChange={handleCreateContactPersonInputfieldChange }
      value={newContactPerson.mobile_number}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Mobile"
      pattern="\d{10}" 
            maxLength="10"
    />
  </div>  
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Other_details" className="block text-xs font-regular text-[#212529] w-1/6">
      Other Details 
    </label>
    <input
      type="text"
      id="designation"
      onChange={handleCreateContactPersonInputfieldChange }
      value={newContactPerson.designation}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Designation" />
  </div>  
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button className="px-2 py-1 bg-[#f7525a]  text-white rounded-md space-x-2 flex items-center" onClick={handleCreateContactPerson}>
<span>Save</span>
  {isSaving && (<Spinner/>)}
</button>
        <button onClick={handleClosePopup} 
       className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
        isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
      }`}
      disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{ShowEditContactPersonPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '70vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md">
    Edit Contact Person
    </h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px '>
    {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )}
  <div className="w-full flex flex-row items-center mb-4 space-x-6 mt-5 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Name
    </label>
    <div className='w-5/6 flex justify-between'>
      <input
        type="text"
        id="name"
        value={selectedContactPerson ? selectedContactPerson.name : ''}
        placeholder="Name"
        onChange={handleUpdateContactPersonInputfieldChange}
        className="w-full py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"/>
    </div>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Email Address
    </label>
    <input
      type="email"
      id="email"
      value={selectedContactPerson ? selectedContactPerson.email : ''}
      onChange={handleUpdateContactPersonInputfieldChange}
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Email Address"    />
  </div> 
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Contact Info 
    </label>
    <input
      type="text"
      id="mobile_number"
      onChange={handleUpdateContactPersonInputfieldChange}
      value={selectedContactPerson ? selectedContactPerson.mobile_number : ''}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Mobile"
      pattern="\d{10}" 
            maxLength="10"
    />
  </div>  
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Other_details" className="block text-xs font-regular text-[#212529] w-1/6">
      Other Details 
    </label>
    <input
      type="text"
      id="designation"
      onChange={handleUpdateContactPersonInputfieldChange}
      value={selectedContactPerson ? selectedContactPerson.designation : ''}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Designation" />
  </div>  
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
      onClick={handleUpdateContactPerson}>
<span>Update</span>
 {isSaving && (<Spinner/>)}
</button>
        <button onClick={handleClosePopup} 
         className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{ShowDeleteContactPersonPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
  style={{    maxWidth: '35vw', maxHeight: '30vh',}}>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='pt-4 pl-4 '>
  <div className="w-full items-center p-5">
    <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
    Do you want to delete the contact person?
    </label>
  </div>  
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md" onClick={handleConfirmDelete}>
Delete  {isSaving && (
    <Spinner/>
  )}
</button>
        <button onClick={handleClosePopup} 
        className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{attachmentPopUp && (
  <div ref={ref} className="absolute right-5 z-10 top-28 bg-white shadow-md border border-gray-200 rounded-[0.5rem] w-1/5">
    <div className="bg-gray-100 py-2 px-4 border-b flex justify-between items-center">
      <h2 className="text-md font-semibold">Attachments</h2>
      <button
        onClick={handleclose}
        className="text-[#f7525a] hover:text-[#d63a42] transition-colors"
        aria-label="Close"
        >
        <FontAwesomeIcon icon={faX} className='w-3 h-3'/>
        </button>
    </div>
   
    <div className="p-4 border-b">
  {selectedFile ? (
    <div className="flex items-center justify-between mb-2">
      <span className="text-sm text-gray-600 text-center">{selectedFile.name}</span>
      <div className="flex items-center space-x-2">
       {/*  <button
          className="text-xs text-[#F7525A]"
          onClick={handleSaveFile}
          disabled={!selectedFile}
        >
          Save
        </button> */}
        <button
          onClick={handleRemoveFile}
          className="text-[#F7525A] hover:text-[#d63a42]"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  ) : attachments.length > 0 ? (
    attachments.map((attachment, index) => (
      <div key={index} className="flex items-center justify-between mb-2">
        <span 
        className="text-sm text-blue-500 text-center cursor-pointer"
        onClick={() => handleViewAttachment(attachment)}
      >
        {attachment}
      </span>
      </div>
      
    ))
  ) : (
    <p className="text-sm text-gray-600 text-center">No files attached</p>
  )}
</div>

{isImageModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-4 rounded-lg relative">
      <button
        onClick={handleclose}
        className="absolute top-2 right-2 text-[#f7525a] hover:text-[#d63a42] transition-colors"
        aria-label="Close"
      >
        <FontAwesomeIcon icon={faX} className='w-3 h-3'/>
      </button>
      {fileViewMode === 'image' && (
        <img
          src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${selectedAttachment}`}
          alt="Attachment"
          className="max-w-full max-h-[80vh]"
        />
      )}
      {fileViewMode === 'pdf' && (
          <div className="w-[800px] h-[600px]">
          <iframe
            src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${selectedAttachment}`}
            title="PDF Viewer"
            width="100%"
            height="100%"
            className="border-none"
          />
        </div>
      )}
      {fileViewMode === 'office' && (
         <div className="w-[800px] h-[600px]">
         <iframe
           src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES + selectedAttachment)}`}
           title="Office Document Viewer"
           width="100%"
           height="100%"
           className="border-none"
         />
       </div>
      )}
      {fileViewMode === 'unsupported' && (
        <p>This file type is not supported for preview. Please download to view.</p>
      )}
    </div>
  </div>
)}



    
    <div className="p-4">
      <div 
        className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center hover:border-blue-500 transition-colors "
        
      >
        <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faUpload} className="text-blue-400 mr-2 cursor-pointer" onClick={() => document.getElementById('fileInput').click()} />
    <p className="text-sm text-gray-600 mr-2 cursor-pointer"
     onClick={() => document.getElementById('fileInput').click()}>
      Upload your files</p>
    <FontAwesomeIcon
  icon={faChevronDown}
  className="text-white rounded-full bg-gray-500 w-3 h-3 p-0.5 text-xs cursor-pointer"
  onClick={(e) => {
    e.stopPropagation();
    setShowUploadOptions(!showUploadOptions);
  }}
/>

  </div>
      </div>
      <p className='text-[0.6rem] text-center mt-1'>You can upload a maximum of 10 files, 10MB each</p>
      </div>
      
      {showUploadOptions && (
  <div className="absolute right-4 top-[calc(100%-30px)] bg-white border border-gray-300 rounded-md shadow-lg z-50 w-48">
    <button className="w-full text-left text-sm text-gray-600 p-2 hover:bg-[#408DFB] hover:text-white hover:rounded-md">
      Attach from desktop
    </button>
    <button className="w-full text-left text-sm text-gray-600 p-2 hover:bg-[#408DFB] hover:text-white hover:rounded-md">
      Attach from documents
    </button>
    <button className="w-full text-left text-sm text-gray-600 p-2 hover:bg-[#408DFB] hover:text-white hover:rounded-md">
      Attach from cloud
    </button>
  </div>
)}



    
    
    <input
      type="file"
      id="fileInput"
      className="hidden"
      onChange={handleFileUpload}
      accept=".pdf,.jpg,.jpeg,.png,.xls,.xlsx,.csv,.doc,.docx"
    />
  </div>
)}



{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* Popup ends */}
    </div>

  );

};



export default ViewCustomer;