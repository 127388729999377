import { faArrowLeft, faBackspace, faBackward, faBalanceScale, faChevronDown, faChevronUp, faDoorOpen, faInfo, faInfoCircle, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { jwtDecode } from "jwt-decode";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FaChevronDown, FaChevronUp, FaDoorOpen, FaInfoCircle, FaRuler } from "react-icons/fa";

const DoorsQuote = () => {
  const navigate = useNavigate();
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('project');
  const categoryId = searchParams.get('category');
  const customerId = searchParams.get('customer');
  const urlToken = searchParams.get('token');
  const [SelectedDoor, setSelectedDoor]=  useState(null);
  const [UOMS , setUOMS] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_APILINK;
    const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
   const [productGroups, setProductGroups] = useState([]);
   const [ProjectMeasurements, setProjectMeasurements]= useState();
   const [Products,setProducts] = useState([]);
   const [DoorHardware, setDoorHardware] = useState([]);
   const [openCategories, setOpenCategories] = useState({});

    const [selectedDoors, setSelectedDoors] = useState({
        mainEntrance: null,
        bedroom: null,
        bathroom: null,
      });
      const [showSelectedDoor, setShowSelectedDoor] = useState(false);
      const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
      const [showQuotationPopup, setShowQuotationPopup] = useState(false);

      const [selectedDoorDetails, setSelectedDoorDetails] = useState(null);
      const [selectedProducts, setSelectedProducts] = useState({});
      const [selectedDoorHardware, setSelectedDoorHardware] = useState({});
      const [showChangePopup, setShowChangePopup] = useState(false);
      const [selectedCategoryName , setSelectedCategoryName] = useState(null);
      const [expandedGroups, setExpandedGroups] = useState({});

      const toggleGroups = (categoryName) => {
        setExpandedGroups(prev => ({
          ...prev,
          [categoryName]: !prev[categoryName]
        }));
      };
      


      useEffect(() => {
        if (urlToken) {
          try {
            // Decode the token
            const decodedToken = jwtDecode(urlToken);

            // Set session data
            sessionStorage.setItem('token', urlToken);
            sessionStorage.setItem('userId', decodedToken.userId);
            sessionStorage.setItem('username', decodedToken.username);
            sessionStorage.setItem('roleId', decodedToken.roleId);
            sessionStorage.setItem('organizationId', decodedToken.organizationId);
            
          } catch (error) {
            console.error('Error decoding token:', error);
            // Handle error (e.g., redirect to error page)
          }
        }
      }, [urlToken, searchParams]);
  
    const handleProductGroupComparison = ()=>{
      setShowChangePopup(true)
    }

      const handleDoorSelect = async (categoryName, doorId, door) => {
        try {
          const productsSelected = await handleGetProductsOfItemGroup(doorId) || [];
          //console.log("productsSelected", productsSelected);
      
          const selectedProducts = productsSelected
            .filter(product => product && (product.item_group_id === doorId || product.item_group_id !== doorId))
            .slice(0, 2);
      
          const selectedHardware = DoorHardware.map(group => {
            const leastExpensiveItem = group.products.reduce((min, item) =>
              (item.selling_price < min.selling_price) ? item : min,
              group.products[0]
            );
      
            return {
              ...leastExpensiveItem,
              quantity: door.numberOfDoors || 1
            };
          });
      
          //console.log("selectedHardware", selectedHardware);
      
          setProjectMeasurements(prevState => {
            if (!prevState || !Array.isArray(prevState.projectMeasurements)) return prevState;
      
            const updatedProjectMeasurements = prevState.projectMeasurements.map(category => {
              if (category.name === categoryName) {
                const updatedCategory = { ...category };
                const isSameDoor = updatedCategory.selectedDoorId === doorId;
      
                updatedCategory.selectedDoorId = doorId;
                updatedCategory.IsDoorApplied = true;
                updatedCategory.selectedDoor = door;
                updatedCategory.selectedProducts = selectedProducts;
                updatedCategory.selectedHardware = selectedHardware;
      
                const selectedDoor = productGroups.find(door => door._id === doorId) || {};
      
                if (!isSameDoor) {
                  setSelectedProducts({});
                  setSelectedDoorHardware({});
                }
      
                setSelectedDoorDetails({
                  ...selectedDoor,
                  categoryDetails: updatedCategory
                });
      
                return updatedCategory;
              }
              return category;
            });
      
            return {
              ...prevState,
              projectMeasurements: updatedProjectMeasurements
            };
          });
      
          setOpenCategories(prev => ({
            ...prev,
            [categoryName]: true
          }));
        } catch (error) {
          console.error("Error in handleDoorSelect:", error);
          // Handle the error appropriately, e.g., show an error message to the user
        }
      };
      
      
      const handleClosePopup = () => {
        setShowSelectedDoor(false);
        setSelectedDoorDetails(null);
      };

      useEffect(() => {
       if(categoryId ) {
        getProductGroups();
       }
      }, [categoryId]);

      useEffect(() => {
          getUoms();
       }, []);
    
    
   
       useEffect(() => {
        if(projectId && categoryId) {
          getProjectCategoryMeasuremets();
       }
     }, [projectId , categoryId]);

     useEffect(() => {
      fetchDoorsHardWareGroupsAndProducts();
    }, []);

   

    const fetchDoorsHardWareGroupsAndProducts = async () => {
      const tokn = sessionStorage.getItem('token');
        try {
          const response = await fetch(fetchUrl2 + `product-groups/get-door-hardware-groups-and-products`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': tokn,
            },
          });
          const data = await response.json();
          //console.log(data);
          const output = await handleGetResponse(response, data);
          if(output) {
            setDoorHardware(output);
          }else{
            setDoorHardware([]);
          }
        
         
        } catch (error) {
          console.error('Error fetching product groups:', error);
        }
      };

      const getUoms =  async () => {
        const token = sessionStorage.getItem('token');
        try {
          const response = await fetch(fetchUrl2 + `UOMs/get-all-UOMs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          //console.log(data)
          if(output) {
            setUOMS(output);
          }else{
            setUOMS([]);
          }

        } catch (error) {
          console.error('Error fetching UOMs:', error);
        }
      };
  
      const getProductGroups = async () => {
        const decodedToken = jwtDecode(urlToken);
        const organizationId = decodedToken.organizationId;
        //console.log('organizationId:', organizationId);
        const tokn = urlToken;
        try {
          const response = await fetch(fetchUrl2 + `product-groups/get-door-category-product-groups?product_category_id=${categoryId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': tokn,
            },
          });
          const data = await response.json();
         // console.log(data)
          const output = await handleGetResponse(response, data);
          if(output) {
            setProductGroups(output);
          }else{
            setProductGroups([]);
          }
        
        } catch (error) {
          console.error('Error fetching product groups:', error);
          return [];
        }
      };
    
       const getProjectCategoryMeasuremets = async () => {
        const tokn = sessionStorage.getItem('token');
        try {
          const response = await fetch(fetchUrl + `/project-reports/get-project-measurements-of-door-category?project_id=${projectId}&product_category_id=${categoryId}&organizationId=${sessionStorage.getItem('organizationId')}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': tokn,
            },
          });
          const data = await response.json();
         // console.log(data)
          const output = await handleGetResponse(response, data);
          if(output) {
            setProjectMeasurements(output);
          }else{
            setProjectMeasurements([]);
          }

        } catch (error) {
          console.error('Error fetching product groups:', error);
          return [];
        }
      };

      const handleGetProductsOfItemGroup = async (selectItemId) => {
        const tokn = urlToken;
        try {
          const response = await fetch(fetchUrl2 + `product/get-door-category-products-by-product-group-along-with-flush-door-group-products?product_group_id=${selectItemId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': tokn,
            },
          });
          const data = await response.json();
          //console.log(data)
          const output = await handleGetResponse(response, data);
          if(output) {
            setProducts(output);
            const selectedProducts = output.filter(p=>p.selling_price === 0);
            return selectedProducts;
          }else{
            setProducts([]);
          }
        }catch (error) {
          console.error('Error fetching products:', error);
          return [];
        }
      }

      const calculateDoorPrice = (addOns, groupPrice, area) => {
        let mainDoorPrice = parseFloat(groupPrice) || 0;
      
        if (Array.isArray(addOns) && addOns.length > 0) {
          const addOnsPrice = addOns.reduce((total, addOn) => {
            const price = parseFloat(addOn.selling_price) || 0;
            return total + price;
          }, 0);
          mainDoorPrice += addOnsPrice;
        }
      
        const totalArea = parseFloat(area) || 1;
        const finalPrice = mainDoorPrice * totalArea;
        
        return Math.ceil(finalPrice);
      };
      
      const calculateDoorPricePerSquareFeet = (addOns, groupPrice) => {
        let mainDoorPrice = parseFloat(groupPrice) || 0;
      
        if (Array.isArray(addOns) && addOns.length > 0) {
          const addOnsPrice = addOns.reduce((total, addOn) => {
            const price = parseFloat(addOn.selling_price) || 0;
            return total + price;
          }, 0);
          mainDoorPrice += addOnsPrice;
        }
        
        return Math.ceil(mainDoorPrice);
      };
      
      const handleAddProduct = (product) => {
        if (!product || typeof product !== 'object') {
          console.error('Invalid product object');
          return;
        }
      
        const groupId = product.item_group_id;
      
        setSelectedDoorDetails(prevDetails => {
          if (!prevDetails || !prevDetails.categoryDetails) {
            console.error('Invalid selectedDoorDetails structure');
            return prevDetails;
          }
      
          const currentProducts = Array.isArray(prevDetails.categoryDetails.selectedProducts) 
            ? prevDetails.categoryDetails.selectedProducts 
            : [];
      
          const filteredProducts = currentProducts.filter(p => p && p.item_group_id !== groupId);
          const updatedProducts = [...filteredProducts, { ...product, quantity: 1 }];
      
          return {
            ...prevDetails,
            categoryDetails: {
              ...prevDetails.categoryDetails,
              selectedProducts: updatedProducts
            }
          };
        });
      };
      
      const handleAddRemove = (product) => {
      /*   if (!product || typeof product !== 'object') {
          console.error('Invalid product object');
          return;
        }
      
        setSelectedDoorDetails(prevDetails => {
          if (!prevDetails || !prevDetails.categoryDetails) {
            console.error('Invalid selectedDoorDetails structure');
            return prevDetails;
          }
      
          const currentProducts = Array.isArray(prevDetails.categoryDetails.selectedProducts) 
            ? prevDetails.categoryDetails.selectedProducts 
            : [];
      
          const filteredProducts = currentProducts.filter(p => p && p.item_id !== product.item_id);
      
          const findSubcategoryProduct = Array.isArray(Products) 
            ? Products.find(p => p && p.item_sub_category_id === product.item_sub_category_id && p.selling_price === 0)
            : null;
      
          const updatedProducts = findSubcategoryProduct
            ? [...filteredProducts, { ...findSubcategoryProduct, quantity: 1 }]
            : filteredProducts;
      
          return {
            ...prevDetails,
            categoryDetails: {
              ...prevDetails.categoryDetails,
              selectedProducts: updatedProducts
            }
          };
        }); */
      };
      


      const handleAddDoorHardware = (product) => {
        setSelectedDoorDetails(prevDetails => {
          const currentHardware = prevDetails.categoryDetails.selectedHardware || [];
          
          // Remove any existing product from the same group
          const filteredHardware = currentHardware.filter(
            item => item.product_group_id !== product.product_group_id
          );
      
          // Add the new product
          const updatedHardware = [
            ...filteredHardware,
            { ...product, quantity: prevDetails.categoryDetails.MainAreaCount || 1 }
          ];
      
          return {
            ...prevDetails,
            categoryDetails: {
              ...prevDetails.categoryDetails,
              selectedHardware: updatedHardware
            }
          };
        });
      };
      
      
      const handleRemoveDoorHardware = (product) => {
      /*   setSelectedDoorDetails(prevDetails => ({
          ...prevDetails,
          categoryDetails: {
            ...prevDetails.categoryDetails,
            selectedHardware: prevDetails.categoryDetails.selectedHardware.filter(
              item => item._id !== product._id
            )
          }
        })); */
      };
       
      
      const handleApplyProductsAndGroupToTheAreaType = () => {
        try {
          const doorPrice = calculateDoorPrice();
          const hardwarePrice = calculateHardwarePrice();
          const totalPrice = calculateTotalPrice();
      
          setProjectMeasurements(prevState => {
            if (!prevState || !Array.isArray(prevState.projectMeasurements)) {
              console.error('Invalid project measurements structure');
              return prevState;
            }
      
            return {
              ...prevState,
              projectMeasurements: prevState.projectMeasurements.map(category => {
                if (category.name === selectedDoorDetails?.categoryDetails?.name) {
                  return {
                    ...category,
                    selectedDoor: {
                      door_id: selectedDoorDetails?._id || '',
                      door_name: selectedDoorDetails?.product_group_name || '',
                      door_price: selectedDoorDetails?.selling_price || 0,
                      door_total_price: doorPrice || 0
                    },
                    selectedProducts: selectedDoorDetails?.categoryDetails?.selectedProducts || [],
                    selectedHardware: selectedDoorDetails?.categoryDetails?.selectedHardware || [],
                    selectedDoor: selectedDoorDetails?.categoryDetails?.selectedDoor || {},
                    doorPrice: doorPrice || 0,
                    hardwarePrice: hardwarePrice || 0,
                    totalPrice: totalPrice || 0,
                    IsDoorApplied: true
                  };
                }
                return category;
              })
            };
          });
      
          setShowSelectedDoor(false);
        } catch (error) {
          console.error('Error applying products and group:', error);
          // Handle the error appropriately, e.g., show an error message to the user
        }
      };
      
      
      

  const toggleCategory = (categoryName , door_id) => {
    setOpenCategories(prev => ({
      ...prev,
      [categoryName]: !prev[categoryName]
    }));
  };




  const handleGetProductsDataByGroup = async (selectItemId) => {
    const tokn = sessionStorage.getItem('token');
    try {
      const response = await fetch(`${fetchUrl2}product/get-door-category-products-by-product-group-along-with-flush-door-group-products?product_group_id=${selectItemId}&organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': tokn,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      setProducts(output || []);
      return output || [];
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]);
      return [];
    }
  }
  

  const handleDoorChangeSelection = async (door) => {
    if (!door || !door._id) return;
  
    const selectedDoorId = SelectedDoor.door_id /* ProjectMeasurements?.projectMeasurements?.find(category => category.name === selectedCategoryName)?.selectedDoorId || '' */;
    toggleCategory(selectedCategoryName);
  
    if (door._id === selectedDoorId) {
      const category = ProjectMeasurements?.projectMeasurements?.find(category => category.name === selectedCategoryName);
      if (!category) return;
  
      const productsData = await handleGetProductsDataByGroup(door._id);
      const existingProducts = productsData.filter(product => category.subItems?.some(item => item.sub_item_id === product.item_id));
      
      setSelectedProducts(existingProducts.reduce((acc, product) => {
        const subItem = category.subItems?.find(item => item.sub_item_id === product.item_id);
        if (subItem) {
          acc[product.item_id] = { ...product, ...subItem };
        }
        return acc;
      }, {}));
  
      const existingHardware = DoorHardware.flatMap(group => group.products || [])
        .filter(product => category.hardware?.some(item => item.hardware_id === product._id));
      
      setSelectedDoorHardware(existingHardware.reduce((acc, product) => {
        const hardware = category.hardware?.find(item => item.hardware_id === product._id);
        if (hardware) {
          acc[product._id] = {
            ...product,
            ...hardware,
            quantity: hardware.hardware_quantity || 0
          };
        }
        return acc;
      }, {}));
  
      setSelectedDoorDetails({
        ...door,
        categoryDetails: category
      });
  
      setShowSelectedDoor(true);
      setShowChangePopup(false);
    } else {
      setShowChangePopup(false);
      handleDoorSelect(selectedCategoryName, door._id);
      setSelectedProducts({})
    }
  };
  
  const handleSelectDoorCustamisation = (category , door) => {
    setSelectedCategoryName(category.name);
    setShowSelectedDoor(true);
     setSelectedDoorDetails({
      ...door,
      categoryDetails: category
    }); 
    //setSelectedDoor(door)
  }
 
  const calculateSelectedDoorPrice = () => {
    const doorPrice = selectedDoorDetails?.selling_price || 0;
    const area = selectedDoorDetails?.categoryDetails?.MainAreaValue || 0;
    const addOnsPrice = selectedDoorDetails?.categoryDetails?.selectedProducts?.reduce((total, product) => {
      return total + (parseFloat(product.selling_price) || 0);
    }, 0) || 0;
    return Math.round((doorPrice + addOnsPrice) * area);
  };
  
  const calculateHardwarePrice = () => {
    const mainAreaCount = selectedDoorDetails?.categoryDetails?.MainAreaCount || 1;
    return Math.round(selectedDoorDetails?.categoryDetails?.selectedHardware?.reduce((total, hardware) => {
      return total + ((parseFloat(hardware.selling_price) || 0) * mainAreaCount);
    }, 0) || 0);
  };
  
  const calculateTotalPrice = () => {
    const doorPrice = calculateSelectedDoorPrice();
    const hardwarePrice = calculateHardwarePrice();
    return Math.round(doorPrice + hardwarePrice);
  };
  
  const generateQuotation = () => {
    const organizationId = sessionStorage.getItem('organizationId') || '';
    const quotation = {
      quotation_date: new Date(),
      expiry_date: new Date(new Date().setDate(new Date().getDate() + 30)),
      status: 'Draft',
      quotation_type: 'Group',
      customer_id: customerId || '',
      project_id: projectId || '',
      product_category_id: categoryId || '',
      tax_prefrence: 'Taxable',
      organizationId: organizationId,
      items: []
    };
  
    let subtotal = 0;
    let taxAmount = 0;
  
    if (Array.isArray(ProjectMeasurements?.projectMeasurements)) {
      ProjectMeasurements.projectMeasurements.forEach(category => {
        if (category?.IsDoorApplied && category?.selectedDoor) {
          const doorItem = {
            item_id: category.selectedDoor._id || '',
            item_name: category.selectedDoor.product_group_name || '',
            quantity: category.MainAreaValue || 0,
            selling_price: calculateDoorPricePerSquareFeet(category.selectedProducts || [], category.selectedDoor?.selling_price || 0),
            total: calculateDoorPrice(category.selectedProducts || [], category.selectedDoor?.selling_price || 0, category.MainAreaValue || 0),
            UOM: UOMS.find(uom => uom._id === category.selectedDoor?.UOM_id)?.UOM || 'Sq Ft',
            UOM_id: category.selectedDoor?.UOM_id || '',
            tax_type: 'GST',
            required_order_breakup: (category.selectedProducts || []).map(product => ({
              sub_item_name: product?.item_name || '',
              sub_item_id: product?.item_id || '',
              sub_item_quantity: category.MainAreaValue || 0,
              price: product?.selling_price || 0
            })),
            area_types: {
              area_name: category.name || '',
              area: (category.MainAreaValue || 0).toString(),
              count: (category.MainAreaCount || 0).toString()
            }
          };
  
          quotation.items.push(doorItem);
          subtotal += doorItem.total;
  
          if (Array.isArray(category.selectedHardware)) {
            category.selectedHardware.forEach(hardware => {
              const hardwareItem = {
                item_id: hardware?._id || '',
                item_name: hardware?.product_name || '',
                quantity: category.MainAreaCount || 0,
                selling_price: hardware?.selling_price || 0,
                total: (hardware?.selling_price || 0) * (category.MainAreaCount || 0),
                UOM: UOMS.find(uom => uom._id === hardware?.UOM_id)?.UOM || 'No',
                UOM_id: hardware?.UOM_id || '',
                tax_type: 'GST',
                area_types: {
                  area_name: category.name || '',
                  count: (category.MainAreaCount || 0).toString()
                }
              };
  
              quotation.items.push(hardwareItem);
              subtotal += hardwareItem.total;
            });
          }
        }
      });
    }
  
    taxAmount = subtotal * 0.18;
  
    quotation.subtotal = subtotal;
    quotation.tax_amount = taxAmount;
    quotation.total = subtotal + taxAmount;
  
    return quotation;
  };
  
  const saveDoorQuotation = async () => {
    try {
      const quotation = generateQuotation();
      const tokn = sessionStorage.getItem('token') || '';
      const organizationId = sessionStorage.getItem('organizationId') || '';
      const response = await fetch(fetchUrl2 + `customer/quotations/save-quotation?organizationId=${organizationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': tokn,
        },
        body: JSON.stringify(quotation),
      });
  
      const data = await response.json();
      const output = await handleSaveResponse(response, data);
  
      if (output) {
        setShowPopup(true);
        setPopupType('success');
        setPopupMessage('Quotation saved successfully');
        setTimeout(() => {
          //setShowPopup(false);
          setShowQuotationPopup(true);
          //navigate('/consumer/app');
        }, 500);
      } else {
        throw new Error('Failed to save quotation');
      }
    } catch (error) {
      console.error('Error saving quotation:', error);
      setShowPopup(true);
      setPopupType('error');
      setPopupMessage('An error occurred while saving the quotation');
      setRetryFunction(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      });
    }
  };
  
  const handleTotalPriceInfo = () => {
    setShowPriceBreakdown(true);
  };

  
  
  return (
<div className="bg-[#F0F4F8] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
  <h1 className="text-4xl font-bold mb-6 text-center text-[#2C3E50]">Select Your Doors</h1>
  
  {ProjectMeasurements && Array.isArray(ProjectMeasurements.projectMeasurements) && ProjectMeasurements.projectMeasurements.length > 0 ? (
    <>
      {ProjectMeasurements.projectMeasurements.map((category) => {
        const isExpanded = expandedGroups[category.name];

        return (
          <div key={category.name} className="bg-white rounded-lg shadow-lg border border-[#BDC3C7] p-6 mt-2">
            <div className="flex flex-col items-start border-b border-[#ECF0F1] mb-4 pb-4">
              <div className="flex items-start text-xl font-semibold mb-3">
                <FaInfoCircle className="flex-shrink-0 mt-1 mr-2 text-[#3498DB]" />
                <span className="text-[#3498DB]">{category.name || 'Unnamed Category'}</span>
              </div>
              
              <div className="text-lg text-[#2C3E50] mb-2">
                <FaDoorOpen className="inline mr-2 text-[#E74C3C]" />
                Doors: <span className="font-bold">{category.MainAreaCount || 0}</span>
              </div>
              
              <div className="text-lg text-[#2C3E50]">
                <FaRuler className="inline mr-2 text-[#27AE60]" />
                Area: <span className="font-bold">{(category.MainAreaValue || 0).toFixed(2)}</span> <span className="font-semibold">sq.ft</span>
              </div>
            </div>
            
            <div className="bg-[#ECF0F1] p-4 rounded-lg">
              <h3 className="text-xl font-semibold mb-4 text-[#2C3E50]">Choose a Door Type:</h3>
              <div className={`max-h-[${isExpanded ? '600px' : '300px'}] overflow-y-auto mb-4 transition-all duration-300`}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {Array.isArray(productGroups) && productGroups.map((door) => (
                    <div
                      key={door._id}
                      className={`p-4 rounded-lg border cursor-pointer shadow-md transition-all duration-200 ${
                        category.selectedDoorId === door._id
                          ? 'bg-[#3498DB] text-white border-[#2980B9]'
                          : 'bg-white text-[#2C3E50] border-[#BDC3C7] hover:bg-[#F7F9FA]'
                      }`}
                      onClick={() => handleDoorSelect(category.name, door._id, door)}
                    >
                      <h3 className="font-semibold mb-2 text-lg">{door.product_group_name || 'Unnamed Door'}</h3>
                      <p className={`text-lg ${category.selectedDoorId === door._id ? 'text-white' : 'text-[#27AE60]'}`}>
                        ₹{(door.selling_price || 0).toLocaleString()}/<span className="text-sm">
                          {Array.isArray(UOMS) && UOMS.length > 0
                            ? (UOMS.find(p => p._id === door.UOM_id)?.UOM || 'N/A')
                            : ''}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              {productGroups.length > 3 && (
                <div className="text-center">
                  <button
                    onClick={() => toggleGroups(category.name)}
                    className="bg-[#A5ADC4] text-white px-4 py-2 rounded-lg font-semibold hover:bg-[#A5ADC4] transition-colors duration-200"
                  >
                    {isExpanded ? (
                      <>Show Less <FaChevronUp className="inline ml-2" /></>
                    ) : (
                      <>Show More <FaChevronDown className="inline ml-2" /></>
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className="mt-2 flex items-center justify-center p-2 rounded-lg">
  <FontAwesomeIcon icon={faDoorOpen} className="mr-2 text-[#856404]" />
  <span
    className="text-[#856404] font-semibold cursor-pointer hover:underline"
    onClick={() => handleProductGroupComparison(productGroups)}
  >
    Which door is best for you? Click to compare!
  </span>
</div>



{category.IsDoorApplied && (
              <div className="mt-4">
                <div className="bg-[#D6EAF8] p-3 rounded-lg mb-4">
                  <p className="font-semibold">Selected: {category?.selectedDoor?.product_group_name || 'Unnamed Door'}</p>
                </div>
                {openCategories[category.name] && (
                  <div className="bg-white rounded-lg shadow p-4 mb-4">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-bold text-gray-800">{category?.selectedDoor?.product_group_name || 'Unnamed Door'}</h3>
                    <span className="text-md font-semibold ">₹{(category?.selectedDoor?.selling_price || 0).toLocaleString()}</span>
                  </div>
                  <div className="space-y-3 mb-4">
                    {Array.isArray(category?.selectedProducts) && category.selectedProducts.map((item, index) => (
                      <div key={index} className="flex justify-between items-center">
                        <span className="text-sm text-gray-700">{item.item_name || 'Unnamed Item'}</span>
                        <div className="flex items-center">
                          {item.selling_price === 0 ? (
                            <span className="text-xs text-orange-600 mr-2">Included</span>
                          ) : (
                            <span className="text-xs text-green-600 mr-2">+₹{(item.selling_price || 0).toLocaleString()}</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="border-t pt-3 mb-4">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm text-gray-600">Price per Sq Ft</span>
                      <span className="text-sm font-medium">₹{calculateDoorPricePerSquareFeet(category.selectedProducts || [], category.selectedDoor?.selling_price || 0, category.MainAreaValue || 0).toLocaleString()}</span>
                    </div>
                    <div className="flex justify-between items-center font-semibold">
                      <span className="text-base">Total ({(category.MainAreaValue || 0).toFixed(2)} Sq Ft)</span>
                      <span className="text-lg text-blue-600">₹{calculateDoorPrice(category.selectedProducts || [], category.selectedDoor?.selling_price || 0, category.MainAreaValue || 0).toLocaleString()}</span>
                    </div>
                  </div>
                  {Array.isArray(category?.selectedHardware) && category.selectedHardware.length > 0 && (
                    <div className="border-t pt-4">
                      <h4 className="text-lg font-bold text-gray-800 mb-3">Hardware</h4>
                      <div className="space-y-2 mb-3">
                        {category.selectedHardware.map((item, index) => (
                          <div key={index} className="flex justify-between items-center">
                            <span className="text-sm text-gray-700">{item.product_name || 'Unnamed Hardware'}</span>
                            <span className="text-sm font-medium">₹{(item.selling_price || 0).toLocaleString()} x {category.MainAreaCount || 0}</span>
                          </div>
                        ))}
                      </div>
                      <div className="flex justify-between items-center font-semibold">
                        <span className="text-base">Total Hardware</span>
                        <span className="text-lg text-blue-600">
                          ₹{category.selectedHardware.reduce((total, item) => total + ((item.selling_price || 0) * (category.MainAreaCount || 0)), 0).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
               <div className="flex justify-between items-center mt-4">
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => toggleCategory(category.name)}
                  >
                    <FontAwesomeIcon icon={openCategories[category.name] ? faChevronUp : faChevronDown} />
                  </button>
                  <p className="text-sm font-semibold mt-1 text-[#3498DB] hover:underline cursor-pointer" onClick={() => handleSelectDoorCustamisation(category, category?.selectedDoor)}>
                    + Customizations
                  </p>
                </div>  
              </div>
            )}
          </div>
        );
      })}
    </>
  ) : null}

  
<div className="mt-8 flex justify-center">
    <button
      className="w-2/3 md:w-1/2 lg:w-1/3 text-white font-semibold py-3 px-6 rounded-lg bg-[#E74C3C] hover:bg-[#C0392B] transition-colors duration-200 text-xl shadow-lg"
      onClick={saveDoorQuotation}
    >
      Get Your Quote
    </button>
  </div>
  {showChangePopup && (
  <div className="fixed inset-0 bg-[#F0F4F8] bg-opacity-95 overflow-hidden h-full w-full z-50 flex flex-col">
    <div className="relative flex-grow overflow-y-auto p-4 md:p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-[#2C3E50]">Door Comparison</h2>
        <button onClick={() => setShowChangePopup(false)} className="text-[#E74C3C] hover:text-[#C0392B] transition-colors duration-200">
          <FontAwesomeIcon icon={faX} className="text-xl" />
        </button>
      </div>
      <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
        <table className="w-full border-collapse text-sm md:text-base">
          <thead>
            <tr className="bg-[#3498DB] text-white">
              <th className="py-2 px-3 border-b border-[#2980B9] sticky left-0 z-10 bg-[#3498DB]">Type</th>
              <th className="py-2 px-3 border-b border-[#2980B9]">Veneer</th>
              <th className="py-2 px-3 border-b border-[#2980B9]">Laminate</th>
              <th className="py-2 px-3 border-b border-[#2980B9]">WPC</th>
              <th className="py-2 px-3 border-b border-[#2980B9]">Aluminium</th>
              <th className="py-2 px-3 border-b border-[#2980B9]">Flush</th>
              <th className="py-2 px-3 border-b border-[#2980B9]">HDPC</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td className="py-2 px-3 border-b text-center font-bold bg-[#ECF0F1] text-[#2C3E50] sticky left-0 z-10">Cost</td>
              <td className="py-2 px-3 border-b text-center">High</td>
              <td className="py-2 px-3 border-b text-center">Medium</td>
              <td className="py-2 px-3 border-b text-center">Med-High</td>
              <td className="py-2 px-3 border-b text-center">Med-High</td>
              <td className="py-2 px-3 border-b text-center">Low-Med</td>
              <td className="py-2 px-3 border-b text-center">Medium</td>
            </tr>
            <tr>
              <td className="py-2 px-3 border-b text-center font-bold bg-[#ECF0F1] text-[#2C3E50] sticky left-0 z-10">Look</td>
              <td className="py-1 px-2 border-b text-center">Luxurious</td>
              <td className="py-1 px-2 border-b text-center">Various</td>
              <td className="py-1 px-2 border-b text-center">Wood-like</td>
              <td className="py-1 px-2 border-b text-center">Modern</td>
              <td className="py-1 px-2 border-b text-center">Simple</td>
              <td className="py-1 px-2 border-b text-center">Smooth</td>
            </tr>
            <tr>
              <td className="py-2 px-3 border-b text-center font-bold bg-[#ECF0F1] text-[#2C3E50] sticky left-0 z-10">Material</td>
              <td className="py-1 px-2 border-b text-center">Wood veneer</td>
              <td className="py-1 px-2 border-b text-center">Laminate</td>
              <td className="py-1 px-2 border-b text-center">Composite</td>
              <td className="py-1 px-2 border-b text-center">Aluminium</td>
              <td className="py-1 px-2 border-b text-center">Eng. wood</td>
              <td className="py-1 px-2 border-b text-center">HDPE</td>
            </tr>
            <tr>
              <td className="py-2 px-3 border-b text-center font-bold bg-[#ECF0F1] text-[#2C3E50] sticky left-0 z-10">Moisture</td>
              <td className="py-1 px-2 border-b text-center">Moderate</td>
              <td className="py-1 px-2 border-b text-center">Good</td>
              <td className="py-1 px-2 border-b text-center">Excellent</td>
              <td className="py-1 px-2 border-b text-center">Excellent</td>
              <td className="py-1 px-2 border-b text-center">Moderate</td>
              <td className="py-1 px-2 border-b text-center">Excellent</td>
            </tr>
            <tr>
              <td className="py-2 px-3 border-b text-center font-bold bg-[#ECF0F1] text-[#2C3E50] sticky left-0 z-10">Strength</td>
              <td className="py-1 px-2 border-b text-center">Good</td>
              <td className="py-1 px-2 border-b text-center">Very Good</td>
              <td className="py-1 px-2 border-b text-center">Excellent</td>
              <td className="py-1 px-2 border-b text-center">Very Good</td>
              <td className="py-1 px-2 border-b text-center">Good</td>
              <td className="py-1 px-2 border-b text-center">Very Good</td>
            </tr>
            <tr>
              <td className="py-2 px-3 border-b text-center font-bold bg-[#ECF0F1] text-[#2C3E50] sticky left-0 z-10">Extra</td>
              <td className="py-1 px-2 border-b text-center text-center">Maintenance</td>
              <td className="py-1 px-2 border-b text-center">Easy clean</td>
              <td className="py-1 px-2 border-b text-center">Eco-friendly</td>
              <td className="py-1 px-2 border-b text-center">Lightweight</td>
              <td className="py-1 px-2 border-b text-center">Cost-effective</td>
              <td className="py-1 px-2 border-b text-center">Low maint.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}



  
{showSelectedDoor && selectedDoorDetails && (
  <div className="fixed inset-0 bg-[#F0F4F8] bg-opacity-95 overflow-hidden h-full w-full z-50 flex flex-col">
    <div className="relative flex-grow overflow-y-auto p-5">
      <div className="flex justify-between items-center mb-4">
        <button onClick={handleClosePopup} className="text-[#E74C3C] hover:text-[#C0392B] transition-colors duration-200">  
          <FontAwesomeIcon icon={faArrowLeft} className="text-xl" />
        </button>
        <h2 className="text-2xl font-bold text-[#2C3E50]">{selectedDoorDetails?.categoryDetails?.name || 'Door'} Customizations</h2>
      </div>

      {/* Customizations */}
      <div className="mt-4">
        {selectedDoorDetails && (
           <div className="mb-6">
            {/* Products with non-matching item_group_id */}
            {Array.isArray(Products) && Products.filter(p => p?.item_group_id !== selectedDoorDetails?._id).length > 0 && (
              <>
                <h3 className="text-lg font-bold text-gray-600 mb-3">
                  {productGroups?.find(p => p?._id !== selectedDoorDetails?.doorId)?.product_group_name || 'Other Products'}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6 bg-white rounded-lg shadow-md p-4">
                {Products.filter(p => p?.item_group_id !== selectedDoorDetails?._id).map((product) => (
                    <div key={product?._id} className={`flex flex-row bg-white rounded-lg shadow-md overflow-hidden h-full ${
                      selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                        ? 'border-2 border-[#3498DB]'
                        : 'border border-[#BDC3C7]'
                    }`}>
                       <div className="h-32 w-24 overflow-hidden">
    <img
      src={ImageUrl + (product?.item_image || '')}
      alt={product?.item_name || 'Product Image'}
      className="w-full h-full object-cover"
    />
  </div>
  <div className="p-3 flex flex-col justify-between flex-grow">
    <div>
      <h4 className="text-sm font-medium text-[#2C3E50] mb-1">{product?.item_name || 'Unnamed Product'}</h4>
      <p className="text-xs text-[#7F8C8D]">
        {product?.selling_price === 0 ? (
          <span className="text-[#E67E22] text-xs font-medium">Included</span>
        ) : (
          <>
            <span className="text-[#3498DB] font-medium">+₹{product?.selling_price || 0}</span>
            <span className="ml-1">{UOMS?.length > 0 ? '/' + (UOMS.find(p => p?._id === product?.UOM_id)?.UOM || '') : ''}</span>
          </>
        )}
      </p>
    </div>
    <div className="mt-2">
      <button
        className={`px-4 py-1 rounded text-xs font-bold transition-colors ${
          selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
            ? 'bg-[#3498DB] text-white hover:bg-[#2980B9]'
            : 'bg-[#ECF0F1] text-[#2C3E50] hover:bg-[#BDC3C7]'
        }`}
        onClick={() => selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
          ? handleAddRemove(product)
          : handleAddProduct(product)
        }
      >
        {selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
          ? 'Selected'
          : 'Select'
        }
      </button>
    </div>
  </div>
</div>


                  ))}
                </div>
              </>
            )}

            {/* Products with matching item_group_id */}
            {Array.isArray(Products) && Products.filter(p => p?.item_group_id === selectedDoorDetails?._id).length > 0 && (
  <>
    <h4 className="text-lg font-bold text-[#2C3E50] mb-3">{selectedDoorDetails?.product_group_name || 'Custom Products'}</h4>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6 bg-white rounded-lg shadow-md p-4">
      {Products.filter(p => p?.item_group_id === selectedDoorDetails?._id).map((product) => (
        <div key={product?._id} className={`flex flex-row bg-white rounded-lg shadow-md overflow-hidden h-full ${
          selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
            ? 'border-2 border-[#3498DB]'
            : 'border border-[#BDC3C7]'
        }`}>
          <div className="h-32 w-24 overflow-hidden">
            <img
              src={ImageUrl + (product?.item_image || '')}
              alt={product?.item_name || 'Product Image'}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="p-3 flex flex-col justify-between flex-grow">
            <div>
              <h4 className="text-sm font-medium text-[#2C3E50] mb-1">{product?.item_name || 'Unnamed Product'}</h4>
              <p className="text-xs text-[#7F8C8D]">
                {product?.selling_price === 0 ? (
                  <span className="text-[#E67E22] text-xs font-medium">Included</span>
                ) : (
                  <>
                    <span className="text-[#3498DB] font-medium">+₹{product?.selling_price || 0}</span>
                    <span className="ml-1">{UOMS?.length > 0 ? '/' + (UOMS.find(p => p?._id === product?.UOM_id)?.UOM || '') : ''}</span>
                  </>
                )}
              </p>
            </div>
            <div className="mt-2">
              <button
                className={`px-4 py-1 rounded text-xs font-bold transition-colors ${
                  selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                    ? 'bg-[#3498DB] text-white hover:bg-[#2980B9]'
                    : 'bg-[#ECF0F1] text-[#2C3E50] hover:bg-[#BDC3C7]'
                }`}
                onClick={() => selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                  ? handleAddRemove(product)
                  : handleAddProduct(product)
                }
              >
                {selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id) ? 'Selected' : 'Select'}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
)}
          </div>
        )}
      </div>

      {/* Door Hardware */}
      <h2 className="text-2xl font-bold mb-4 text-[#2C3E50]">Door Hardware</h2>
<div className="mt-6 bg-white rounded-lg shadow-md p-4">
  {Array.isArray(DoorHardware) && DoorHardware.map((productGroup) => (
    <div key={productGroup?._id} className="mb-8">
      <h3 className="text-lg font-bold mb-3 text-[#34495E]">{productGroup?.product_group_name || 'Hardware Group'}</h3>
      <hr className="mb-4 border-[#ECF0F1]" />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {Array.isArray(productGroup?.products) && productGroup.products.map((product) => (
          <div
            key={product?._id}
            className={`flex flex-row bg-white rounded-lg shadow-md overflow-hidden h-full ${
              selectedDoorDetails?.categoryDetails?.selectedHardware?.some(p => p?._id === product?._id)
                ? 'border-2 border-[#3498DB]'
                : 'border border-[#BDC3C7]'
            }`}
          >
            <div className="h-32 w-24 overflow-hidden">
              <img
                src={ImageUrl + (product?.product_image || '')}
                alt={product?.product_name || 'Hardware Image'}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-3 flex flex-col justify-between flex-grow">
              <div>
                <h4 className="text-sm font-medium text-[#2C3E50] mb-1">{product?.product_name || 'Unnamed Hardware'}</h4>
                <p className="text-xs text-[#7F8C8D]">
                  ₹{product?.selling_price || 0} {UOMS?.length > 0 ? '/' + (UOMS.find(p => p?._id === product?.UOM_id)?.UOM || '') : ''}
                </p>
              </div>
              <div className="mt-2">
                <button
                  className={`px-4 py-1 rounded text-xs font-bold transition-colors ${
                    selectedDoorDetails?.categoryDetails?.selectedHardware?.some(p => p?._id === product?._id)
                      ? 'bg-[#3498DB] text-white hover:bg-[#2980B9]'
                      : 'bg-[#ECF0F1] text-[#2C3E50] hover:bg-[#BDC3C7]'
                  }`}
                  onClick={() => selectedDoorDetails?.categoryDetails?.selectedHardware?.some(p => p?._id === product?._id)
                    ? handleRemoveDoorHardware(product)
                    : handleAddDoorHardware(product)
                  }
                >
                  {selectedDoorDetails?.categoryDetails?.selectedHardware?.some(p => p?._id === product?._id)
                    ? 'Selected'
                    : 'Select'
                  }
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
</div>

    {/* Total Price and Apply Button */}
    <div className="bg-white border-t border-[#BDC3C7] px-4 py-4">
  <div className="flex justify-between items-center mb-2">
    <span className="text-sm font-semibold text-[#34495E]">Doors Price:</span>
    <span className="text-sm font-semibold text-[#2980B9]">₹ {calculateSelectedDoorPrice()}</span>
  </div>
  <div className="flex justify-between items-center mb-2">
    <span className="text-sm font-semibold text-[#34495E]">Hardware Price ({selectedDoorDetails?.categoryDetails?.MainAreaCount || 0} Doors):</span>
    <span className="text-sm font-semibold text-[#2980B9]">₹ {calculateHardwarePrice()}</span>
  </div>
  <div className="flex justify-between items-center mb-4">
    <span className="text-lg font-semibold text-[#2C3E50]">Total Price:</span>
    <div className="flex items-center">
      <span className="text-lg font-bold text-[#2980B9] mr-2">₹ {calculateTotalPrice()}</span>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="text-[#3498DB] cursor-pointer"
        onClick={() => handleTotalPriceInfo()}
      />
    </div>
  </div>

  {showPriceBreakdown && (
    <div className="fixed inset-0 bg-[#2C3E50] bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full max-h-[90vh] relative">
        <button
          className="absolute top-2 right-2 mr-2 text-[#E74C3C] hover:text-[#C0392B] transition-colors duration-300"
          onClick={() => setShowPriceBreakdown(false)}
        >
          <FontAwesomeIcon icon={faXmark} className="text-xl" />
        </button>
        <div className="overflow-y-auto max-h-[90vh] p-6 pt-10">
          <h2 className="text-2xl font-bold mb-4 text-[#2C3E50] border-b border-[#BDC3C7] pb-2">Order Summary</h2>
          
          <div className="mb-4">
            <div className="bg-[#ECF0F1] p-3 rounded">
              <div className="flex justify-between items-center mb-2">
                <p className="text-sm font-medium text-[#2C3E50]">{selectedDoorDetails?.product_group_name}</p>
                <p className="text-sm font-medium text-[#3498DB]">₹{selectedDoorDetails?.selling_price}/{UOMS.find(uom => uom._id === selectedDoorDetails?.UOM_id)?.UOM || 'sq ft'}</p>
              </div>
              <ul className="list-none pl-4">
                {selectedDoorDetails?.categoryDetails?.selectedProducts.map((product, index) => (
                  <li key={index} className="text-xs mb-1 flex justify-between">
                    <span className="text-[#34495E]">{product.item_name}</span>
                    <span className={`font-medium ${product.selling_price === 0 ? 'text-[#E67E22]' : 'text-[#3498DB]'}`}>
                      {product.selling_price === 0 ? 'Included' : ` + ₹${(product.selling_price).toFixed(2)}`}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-2 flex justify-between">
              <p className="text-sm text-[#34495E]"><span className="font-medium">Area:</span> {selectedDoorDetails?.categoryDetails?.MainAreaValue} sq ft</p>
              <p className="text-sm">
                <span className="font-medium text-[#34495E]">Total Door Price:</span>
                <span className="text-[#3498DB] ml-2">₹{calculateSelectedDoorPrice()}</span>
              </p>
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-[#2C3E50]">Hardware</h3>
            <ul className="space-y-2">
              {selectedDoorDetails?.categoryDetails?.selectedHardware.map((hardware, index) => (
                <li key={index} className="flex justify-between items-start text-sm">
                  <span className="flex-1 text-[#34495E]">{hardware.product_name}</span>
                  <span className="font-medium ml-4 text-right text-[#3498DB]">
                    ₹{hardware.selling_price} x {selectedDoorDetails?.categoryDetails?.MainAreaCount} =
                    ₹{(hardware.selling_price * selectedDoorDetails?.categoryDetails?.MainAreaCount).toFixed(2)}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-6 border-t border-[#BDC3C7] pt-4">
            <div className="flex justify-between mb-2">
              <span className="font-medium text-[#34495E]">Door Price (including customizations):</span>
              <span className="text-[#3498DB]">₹{calculateSelectedDoorPrice()}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-medium text-[#34495E]">Hardware Price:</span>
              <span className="text-[#3498DB]">₹{calculateHardwarePrice()}</span>
            </div>
            <div className="flex justify-between text-lg font-bold mt-2 pt-2 border-t border-[#BDC3C7]">
              <span className="text-[#2C3E50]">Total:</span>
              <span className="text-[#3498DB]">₹{calculateTotalPrice()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}

  <div className="flex justify-between items-center">
    <span className="text-md font-semibold text-[#2C3E50]">Apply to {selectedDoorDetails?.categoryDetails?.name || 'Door'}:</span>
    <button
      className="bg-[#3498DB] text-white py-2 px-6 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
      onClick={() => handleApplyProductsAndGroupToTheAreaType()}
    >
      Apply
    </button>
  </div>
</div>
  </div>
)}

{showQuotationPopup && (
  <div className="fixed inset-0 bg-[#2C3E50] bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
      <h2 className="text-2xl font-bold mb-4 text-[#2C3E50]">Quotation Saved</h2>
      <p className="mb-6 text-[#34495E]">
        Doors quotation has been generated and saved successfully. We will get back to you for production measurements.
      </p>
      <button
        className="w-full bg-[#3498DB] text-white py-2 px-4 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
        onClick={() => {
          setShowQuotationPopup(false);
          navigate('/consumer/app');
        }}
      >
        OK
      </button>
    </div>
  </div>
)}



  </div>
  
  );
};

export default DoorsQuote;


